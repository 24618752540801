import React, { Fragment, useEffect } from 'react';
import Countly from 'countly-sdk-web';
import { useLocation } from 'react-router-dom';
import { checkHighestRole, extractEmail } from '@/config/utils';
import debounce from 'lodash.debounce';

type service =
  | 'assets'
  | 'explore'
  | 'gse'
  | 'plugin'
  | 'sd'
  | 'recom'
  | 'routine'
  | 'vda'
  | 'user'
  | 'did-settings'
  | 'qr_lounge'
  | 'find'
  | 'Admin';

type action = string;

type activity = string;

type ActivityObject = {
  service: service;
  submenu: string;
  action: action;
  activity_desc: any;
  id?: string;
  state?: string;
  title?: string;
  country?: string;
  page_type?: string;
  device_type?: string;
  template_type?: string;
  os_type?: string;
  action_type?: string;
  icon_type?: string;
  ex_icon_key?: string;
};

const appVersion = process.env.REACT_APP_VERSION
  ? process.env.REACT_APP_VERSION.toUpperCase()
  : '2.0.0-7';

const pathMenuMapping = [
  {
    path: '/assets/images',
    menu: 'Images',
    service: 'assets',
  },
  {
    path: '/assets/icons',
    menu: 'Icons',
    service: 'assets',
  },
  {
    path: '/assets/existing',
    menu: 'Ex-Icons',
    service: 'assets',
  },
  {
    path: '/assets/resource_types',
    menu: 'OCF Device Type',
    service: 'assets',
  },
  {
    path: '/assets/history',
    menu: 'Assets History',
    service: 'assets',
  },
  {
    path: '/explore/post',
    menu: 'Post',
    service: 'explore',
  },
  {
    path: '/explore/tags',
    menu: 'Tags',
    service: 'explore',
  },
  {
    path: '/explore/history',
    menu: 'Explore History',
    service: 'explore',
  },
  {
    path: '/gse/content/pages',
    menu: 'Pages',
    service: 'gse',
  },
  {
    path: '/gse/content/herobanners',
    menu: 'Herobanners',
    service: 'gse',
  },
  {
    path: '/gse/content/notices',
    menu: 'Notices',
    service: 'gse',
  },
  {
    path: '/gse/content/history',
    menu: 'GSE History',
    service: 'gse',
  },
  {
    path: '/plugin/tips',
    menu: 'Tips',
    service: 'plugin',
  },
  {
    path: '/plugin/support-page',
    menu: 'Support Pages',
    service: 'plugin',
  },
  {
    path: '/plugin/history',
    menu: 'Plugin History',
    service: 'plugin',
  },
  {
    path: '/recommendation/campaign',
    menu: 'My Campaign',
    service: 'recom',
  },
  {
    path: '/recommendation/push-recommendation',
    menu: 'Push Recommendation',
    service: 'recom',
  },
  {
    path: '/recommendation/history',
    menu: 'Recommendation History',
    service: 'recom',
  },
  {
    path: '/routine/preset_routine',
    menu: 'Preset Routine',
    service: 'routine',
  },
  {
    path: '/routine/routine_template',
    menu: 'Routine Template',
    service: 'routine',
  },
  {
    path: '/routine/history',
    menu: 'Routine History',
    service: 'routine',
  },
  {
    path: '/mall/local_resource/product',
    menu: 'Local Resource - Product',
    service: 'sd',
  },
  {
    path: '/mall/global_resource/lifestyle',
    menu: 'Global Resource - Life',
    service: 'sd',
  },
  {
    path: '/mall/global_resource/category',
    menu: 'Global Resource - Category',
    service: 'sd',
  },
  {
    path: '/mall/global_resource/supercategory',
    menu: 'Global Resource - Super Category',
    service: 'sd',
  },
  {
    path: '/mall/global_resource/brand',
    menu: 'Global Resource - Brand',
    service: 'sd',
  },
  {
    path: '/mall/universal_resource/deeplink',
    menu: 'Universal Resource - Deeplink',
    service: 'sd',
  },
  {
    path: '/mall/history',
    menu: 'Supported Device History',
    service: 'sd',
  },
  {
    path: '/vda/device',
    menu: 'Devices',
    service: 'vda',
  },
  {
    path: '/vda/resource',
    menu: 'Resources',
    service: 'vda',
  },
  {
    path: '/vda/history',
    menu: 'VDA History',
    service: 'vda',
  },
  {
    path: '/did-settings',
    menu: 'DID Settings',
    service: 'Admin',
  },
  {
    path: '/dashboard/users',
    menu: 'Dashboard User',
    service: 'Admin',
  },
  {
    path: '/dashboard/content',
    menu: 'Dashboard Content',
    service: 'Admin',
  },
  {
    path: '/user',
    menu: 'User Management',
    service: 'Admin',
  },
  {
    path: '/notification',
    menu: 'Notification Manager',
    service: 'Admin',
  },
  {
    path: '/support',
    menu: 'Support',
    service: 'Support',
  },
  {
    path: '/qr-lounge/venue',
    menu: 'Venue Management',
    service: 'QR Lounge Web',
  },
];

const getService = (path: string) => {
  let temp = {
    menu: 'others',
    service: 'others',
  };
  pathMenuMapping.forEach((p: any) => {
    if (path.toLowerCase().indexOf(p.path.toLowerCase()) > -1) {
      temp.menu = p.menu;
      temp.service = p.service;
      return;
    }
  });
  return temp;
};

const a = {
  init: () => {
    Countly.init({
      app_key: process.env.REACT_APP_COUNTLY_APP_KEY,
      url: process.env.REACT_APP_COUNTLY_URL,
      app_version: process.env.REACT_APP_VERSION,
    });
  },
  userID: '',
  screenResolution: '',
  userRoles: {},
  department: '',
  fullName: '',
  lastPage: '',
  setUser: (user?: any, height?: any, width?: any) => {
    a.userID = '';
    a.userRoles = {};
    a.screenResolution = `${height}x${width}`;
    if (user) {
      a.userID = user.email;
      a.userRoles = user.roles;
      a.fullName = user.fullName || '';
      a.department =
        (a.userID.indexOf('@smartthings.com') === -1 && user.department) ||
        (a.userID.indexOf('@smartthings.com') > -1 ? 'SmartThings' : '');
    }
  },
  segmentationUser: () => {
    return {
      user_id: extractEmail(a.userID),
      department: a.department,
      screen_resolution: a.screenResolution,
    };
  },
  segmentation: (event: ActivityObject) => {
    let role =
      event.service === 'user' || event.service === 'did-settings'
        ? checkHighestRole(a.userRoles).highestRoleValue
        : a.userRoles[event.service];
    return { ...a.segmentationUser(), ...event, role: role, path: a.lastPage };
  },
  addEvent: debounce((act: ActivityObject) => {
    Countly.add_event({
      key: '[CLY]_button_event',
      segmentation: a.segmentation(act),
    });
  }, 100),
  addTransition: (act: ActivityObject) => {
    console.log('countly data', act);
    Countly.add_event({
      key: '[CLY]_action_transition',
      segmentation: a.segmentation(act),
    });
  },
};

export const activity = a;

const ActivityMonitoring = (props: any) => {
  const loc = useLocation();

  useEffect(() => {
    a.init();
    Countly.track_sessions();
  }, []);
  useEffect(() => {
    const path = loc.pathname + loc.search;
    const menuService = getService(path);
    a.lastPage = path;
    // Add custom event per page
    Countly.add_event({
      key: '[CLY]_page_view',
      segmentation: {
        path: path,
        version: appVersion,
        menu: menuService.menu,
        service: menuService.service,
        ...a.segmentationUser(),
      },
    });
  }, [loc]);
  return <Fragment>{props.children}</Fragment>;
};

export default ActivityMonitoring;
