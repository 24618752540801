import React from 'react';
import Icon, {
  PlayCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  RocketOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import PublishIconImage from '@/assets/icon_publish.png';
import { ReactComponent as MoveIconImage } from '@/assets/icon_move.svg';
import { ReactComponent as PreviewIconImage } from '@/assets/icon_preview.svg';
import { ReactComponent as viewIconImage } from '@/assets/icon_view.svg';
import { ReactComponent as EditIconImage } from '@/assets/icon_edit.svg';
import { ReactComponent as DeleteIconImage } from '@/assets/icon_delete.svg';
import { ReactComponent as DisableIconImage } from '@/assets/icon_disable.svg';
import { ReactComponent as SubmitIconImage } from '@/assets/icon_submit.svg';
import { ReactComponent as ArchiveIconImage } from '@/assets/icon_archive.svg';
import { ReactComponent as UnarchiveIconImage } from '@/assets/icon_unarchive.svg';
import ApproveIconImage from '@/assets/icon_approve.png';
import { ReactComponent as RejectIconImage } from '@/assets/icon_reject.svg';
import { ReactComponent as PreviewJSONIconImage } from '@/assets/icon_view_json.svg';
import { ReactComponent as HistoryIconImage } from '@/assets/icon_history.svg';
import { ReactComponent as EditJSONIconImage } from '@/assets/icon_edit_json.svg';
import { ReactComponent as UnpublishIconImage } from '@/assets/icon_unpublish.svg';
import { ReactComponent as ExportIconImage } from '@/assets/icon_export.svg';
import { getImage24BySource } from '@/components/utils';
import { ReactComponent as GlobalRoleImage } from '@/assets/role_global.svg';
import { ReactComponent as CountryRoleImage } from '@/assets/role_country.svg';
import { ReactComponent as OperationRoleImage } from '@/assets/role_operation.svg';
import { ReactComponent as CloneIconImage } from '@/assets/icon_replicate.svg';
import { ReactComponent as ActivateIconImage } from '@/assets/icon_activate.svg';
import { ReactComponent as DeactivateIconImage } from '@/assets/icon_inactive.svg';

import global_manager from '@/assets/role_info/GlobalManager.png';
import global_general_admin from '@/assets/role_info/GlobalGeneralAdmin.png';
import global_service_admin from '@/assets/role_info/GlobalServiceAdmin.png';
import global_viewer from '@/assets/role_info/GlobalViewer.png';
import country_manager from '@/assets/role_info/CountryManager.png';
import country_general_admin from '@/assets/role_info/CountryGeneralAdmin.png';
import country_service_admin from '@/assets/role_info/CountryServiceAdmin.png';
import country_viewer from '@/assets/role_info/CountryViewer.png';
import operation from '@/assets/role_info/Operation.png';
import AssetsImage from '@/assets/s-guide-assets.png';
import ExploreImage from '@/assets/s-guide-explore.png';
import GSEImage from '@/assets/s-guide-gse.png';
import PluginImage from '@/assets/s-guide-plugin.png';
import RecomImage from '@/assets/s-guide-recom.png';
import RoutineImage from '@/assets/s-guide-routine.png';
import SDImage from '@/assets/s-guide-sd.png';
import VDAImage from '@/assets/s-guide-vda.png';
import FindImage from '@/assets/s-guide-find.png';
import QRLoungeImage from '@/assets/s-guide-qr-lounge.png';
import service_icon from '@/assets/service-icon.svg';
import b2b_icon from '@/assets/b2b-icon.svg';
import { Row, Col, Image } from 'antd';

export const APP_NAME: string = 'SmartThings CMS';
export const KEYCLOAK_HOST: string =
  process.env && process.env.REACT_APP_KEYCLOAK_HOST
    ? process.env.REACT_APP_KEYCLOAK_HOST
    : '';
export const KEYCLOAK_REALM: string =
  process.env && process.env.REACT_APP_KEYCLOAK_REALM
    ? process.env.REACT_APP_KEYCLOAK_REALM
    : '';
export const KEYCLOAK_CLIENTID: string =
  process.env && process.env.REACT_APP_KEYCLOAK_CLIENTID
    ? process.env.REACT_APP_KEYCLOAK_CLIENTID
    : '';
export const CPB_URL: string =
  process.env && process.env.REACT_APP_CPB_URL
    ? process.env.REACT_APP_CPB_URL
    : '';

export const COMPANY_NAME = 'SAMSUNG';
export const UPDATE_TOKEN_BEFORE = 10;
export const UNAUTHORIZED_URL = '/401';
export const NOACCESS_URL = '/403';
export const NOTFOUND_URL = '/404';
export const INTERNALSERVER_URL = '/500';

// export const USE_SECRET_SERVER = SECRET_SERVER_URL.length > 0;
export const DID_SETTING_BRANCH = process.env.REACT_APP_DID_BRANCH_NAME || '';
export const SECRET_SERVER_URL = process.env.REACT_APP_SECRET_SERVER_URL || '';
export const VDA_PRIVATE_KEY = process.env.REACT_APP_VDA_PRIVATE_KEY || '';

export const gridOption = {
  field: { xl: 24, lg: 24, md: 24, sm: 24 },
  midField: { xl: 23, lg: 20, md: 20, sm: 15 },
  labelCol: { xl: 24, lg: 24, md: 24, sm: 24 },
  labelCol2: { xl: 24, lg: 24, md: 24, sm: 24 },
  labelColNested: { xl: 24, lg: 24, md: 24, sm: 24 },
  labelColRight: { xl: 2, lg: 2, md: 4, sm: 4 },
  labelColMiddle: { xl: 1, lg: 1, md: 2, sm: 2 },
  labelCheckbox: { xl: 10, lg: 10, md: 10, sm: 10 },
  wrapperCol: { xl: 24, lg: 24, md: 24, sm: 24 },
  wrapperCol2: { xl: 18, lg: 18, md: 18, sm: 18 },
  wrapperColFull: { xl: 24, lg: 24, md: 24, sm: 24 },
  wrapperColMiddle: { xl: 9, lg: 9, md: 7, sm: 7 },
  wrapperColNested: { xl: 24, lg: 24, md: 24, sm: 24 },
  wrapperColImage: { xl: 18, lg: 18, md: 18, sm: 18 },
  wrapperCheckbox: { xl: 4, lg: 4, md: 4, sm: 4 },
  wrapperCheckboxAll: { xl: 18, lg: 18, md: 18, sm: 18 },
  wrapperCheckboxDevices: { xl: 7, lg: 7, md: 7, sm: 7 },
  wrapperImage: { xl: 5, lg: 5, md: 5, sm: 5 },
  wrapperColHalf: { xl: 15, lg: 15, md: 11, sm: 11 },
};

export const COLUMN_WIDTH = {
  VERY_LONG: '640px',
  LONG: '320px',
  MID: '210px',
  SHORT: '130px',
  VERY_SHORT: '80px',
};

export const KC_STATUS = {
  NONE: '',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
};

export const STCMS_ENV: any = {
  DEV: 'Development',
  STG: 'Staging',
  ACT: 'Acceptance',
  PROD: 'Production',
};

export const DATE_FORMAT_FULL_TIME = 'DD MMM YYYY HH:mm';
export const DATE_FORMAT_ONLY_DATE = 'DD MMM YYYY';

// non-Explore-Retail
export const ROLE_LIST = [
  { value: 'OPERATION', label: 'Operation Admin' },
  { value: 'GLOBAL_MANAGER', label: 'Global Manager' },
  { value: 'GLOBAL_GENERAL_ADMIN', label: 'Global General Admin' },
  { value: 'GLOBAL_SERVICE_ADMIN', label: 'Global Service Admin' },
  { value: 'GLOBAL_VIEWER', label: 'Global Viewer' },
  { value: 'COUNTRY_MANAGER', label: 'Country Manager' },
  { value: 'COUNTRY_GENERAL_ADMIN', label: 'Country General Admin' },
  { value: 'COUNTRY_SERVICE_ADMIN', label: 'Country Service Admin' },
  { value: 'COUNTRY_VIEWER', label: 'Country Viewer' },
  { value: 'VENUE_MANAGER', label: 'Venue Manager' },
  { value: 'VENUE_SERVICE_ADMIN', label: 'Venue Service Admin' },
  { value: 'VENUE_VIEWER', label: 'Venue Viewer' },
];

export const ROLE_GENERAL: Record<string, string> = {
  OPERATION: 'Operation Admin',
  GLOBAL_MANAGER: 'Global Manager',
  GLOBAL_GENERAL_ADMIN: 'Global General Admin',
  GLOBAL_SERVICE_ADMIN: 'Global Service Admin',
  GLOBAL_VIEWER: 'Global Viewer',
  COUNTRY_MANAGER: 'Country Manager',
  COUNTRY_GENERAL_ADMIN: 'Country General Admin',
  COUNTRY_SERVICE_ADMIN: 'Country Service Admin',
  COUNTRY_VIEWER: 'Country Viewer',
  VENUE_MANAGER: 'Venue Manager',
  VENUE_SERVICE_ADMIN: 'Venue Service Admin',
  VENUE_VIEWER: 'Venue Viewer',
};

export const ROLE_TYPE = {
  OPERATION: 'OPERATION',
  GLOBAL_MANAGER: 'GLOBAL_MANAGER',
  GLOBAL_GENERAL_ADMIN: 'GLOBAL_GENERAL_ADMIN',
  GLOBAL_SERVICE_ADMIN: 'GLOBAL_SERVICE_ADMIN',
  GLOBAL_VIEWER: 'GLOBAL_VIEWER',
  COUNTRY_MANAGER: 'COUNTRY_MANAGER',
  COUNTRY_GENERAL_ADMIN: 'COUNTRY_GENERAL_ADMIN',
  COUNTRY_SERVICE_ADMIN: 'COUNTRY_SERVICE_ADMIN',
  COUNTRY_VIEWER: 'COUNTRY_VIEWER',
  VENUE_MANAGER: 'VENUE_MANAGER',
  VENUE_SERVICE_ADMIN: 'VENUE_SERVICE_ADMIN',
  VENUE_VIEWER: 'VENUE_VIEWER',
};

export const ROLE_LEVEL: Record<string, number> = {
  OPERATION: 1,
  GLOBAL_MANAGER: 2,
  GLOBAL_GENERAL_ADMIN: 3,
  GLOBAL_SERVICE_ADMIN: 4,
  GLOBAL_VIEWER: 5,
  COUNTRY_MANAGER: 6,
  COUNTRY_GENERAL_ADMIN: 7,
  COUNTRY_SERVICE_ADMIN: 8,
  COUNTRY_VIEWER: 9,
  VENUE_MANAGER: 10,
  VENUE_SERVICE_ADMIN: 11,
  VENUE_VIEWER: 12,
};

export const ERROR_MSG = {
  connectedDevice: 'Connected Device field is required',
  mobileDevice: 'Mobile device field is required',
  deviceGroup: 'Number of Device Group field is required',
  title: 'Title field is required',
  text: 'Text field is required',
  description: 'Description field is required',
  priority: 'Weight field must be a number',
  installedService: 'Installed Service field is Required',
  carrier: 'Carrier field is Required',
  minAppVersion: 'ST App Version field is required',
  numberOfScene: 'Number of Scene field must be a number',
  languageMap: 'All of language fields must be filled',
  translationText: 'Translation text field cannot be empty',
  translationButtonText: 'Translation button text field cannot be empty',
  translationSubTitleText: 'Translation Subtitle text field cannot be empty',
  subTitleColor: 'Subtitle color field cannot be empty',
  hexColor: 'Input must be a valid HEX color',
  defaultWebUrl: 'Default Web URL field cannot be empty',
  webUrl: 'web URL field cannot be empty',
  urlInvalid: 'Input must be a valid Link format',
  deepLinkInvalid: 'Input must be a valid deeplink format',
  countryUri: 'Input must be a valid Link format',
  countryCid: 'CID field cannot be empty',
  mustInput: 'One of UID and URI fields must be filled',
  uriField: 'URI fields cannot be empty',
  dataField: 'Data fields cannot be empty',
  dataJSON: ' Data field must be valid data JSON',
  translationDisplayName: 'Translation display name cannot be empty',
  actionData: 'Action data cannot be empty',
  invalidAppVersion: 'App version must be a valid format (e.g. "1.0.2")',
  invalidOSVersion: 'OS version must be a valid format (e.g. "1.01")',
  invalidOneUIVersion:
    'One UI version must be a valid format (e.g. "1.01" or "1.0.2")',
  invalidSTKitVersion:
    'SmartThings Kit version must be a valid format (e.g. "1.5.01.0" or "1.5.01.1")',
  countryArray: 'Country is required! cannot be empty',
  languageArray: 'Language is required! cannot be empty',
  routineTemplate: 'Routine template fields cannot be empty',
};

export const TEMPLATE_TYPE = {
  ICON_TEXT: 'ICON_TEXT',
  ICON_ANIMATION_TEXT: 'ICON_ANIMATION_TEXT',
  THUMBNAIL_TEXT: 'THUMBNAIL_TEXT',
  TEXT_ONLY: 'TEXT_ONLY',
  BACKGROUND_TYPE: 'BACKGROUND_TYPE',
};

export const ACTION_RECOMMENDATION_TYPE = {
  RECIPE: 'RECIPE',
  WEB_LINK: 'WEB_LINK',
  SERVICE_PLUGIN: 'SERVICE_PLUGIN',
  DEEP_LINK: 'DEEP_LINK',
  ENDPOINT_APP: 'ENDPOINT_APP',
  GROOVY_SMART_APP: 'GROOVY_SMART_APP',
  SCENE: 'SCENE',
  DEVICE_GROUP: 'DEVICE_GROUP',
  ROUTINE: 'ROUTINE',
  NO_ACTION: 'NO_ACTION',
};

export const ACTION_TYPE = {
  DELETE: 'delete',
  SUBMIT: 'submit',
  APPROVE: 'approve',
  REJECT: 'reject',
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  EDIT: 'edit',
  ARCHIVE: 'archive',
  PAUSE: 'pause',
  CREATE: 'create',
  REPLICATE: 'replicate',
  DISCARD: 'discard',
  COMPLETE: 'complete',
  UPLOAD: 'upload',
  UPDATE: 'update',
  DISCARD_ALL: 'discard all',
  ENABLE: 'enable',
  DISABLE: 'disable',
  CANCEL: 'cancel',
  SYNC: 'sync',
  CLONE: 'clone',
  EXPORT: 'export',
};

export const ACTION_TYPE_MSG = {
  [ACTION_TYPE.UPDATE]: 'updated',
  [ACTION_TYPE.SUBMIT]: 'submited',
  [ACTION_TYPE.APPROVE]: 'approved',
  [ACTION_TYPE.REJECT]: 'rejected',
  [ACTION_TYPE.DELETE]: 'deleted',
  [ACTION_TYPE.ACTIVATE]: 'activated',
  [ACTION_TYPE.EDIT]: 'edited',
  [ACTION_TYPE.ARCHIVE]: 'archived',
  [ACTION_TYPE.PAUSE]: 'paused',
  [ACTION_TYPE.CREATE]: 'created',
  [ACTION_TYPE.REPLICATE]: 'replicated',
  [ACTION_TYPE.CLONE]: 'cloned',
  [ACTION_TYPE.DISCARD]: 'discarded',
  [ACTION_TYPE.UPLOAD]: 'uploaded',
  [ACTION_TYPE.COMPLETE]: 'completed',
  [ACTION_TYPE.DISCARD_ALL]: 'discarded',
  [ACTION_TYPE.ENABLE]: 'enabled',
  [ACTION_TYPE.DISABLE]: 'disabled',
  [ACTION_TYPE.SYNC]: 'sync',
};

export const SERVICE = {
  ASSETS: 'assets',
  GSE: 'gse',
  PLUGIN: 'plugin',
  RECOMMENDATION: 'recom',
  ROUTINE: 'routine',
  SD: 'sd',
  VDA: 'vda',
  EXPLORE: 'explore',
  FIND: 'find',
  EXPLORE_RETAIL: 'explore_retail',
  QR_LOUNGE: 'qr_lounge',
  MAP_DECOR: 'map_decor',
};

// FIXME: GLOBAL_GENERAL_ADMIN for EXPLORE_RETAIL is still buggy (gives error 500 for the entire brochure API)
export const AVAILABLE_ROLE_FOR_SERVICE: Record<string, string[]> = {
  [SERVICE.ASSETS]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
  ],
  [SERVICE.EXPLORE]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.EXPLORE_RETAIL]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.VENUE_MANAGER,
    ROLE_TYPE.VENUE_SERVICE_ADMIN,
    ROLE_TYPE.VENUE_VIEWER,
  ],
  [SERVICE.GSE]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_SERVICE_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.PLUGIN]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_SERVICE_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.RECOMMENDATION]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_SERVICE_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.ROUTINE]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_SERVICE_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.SD]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
    ROLE_TYPE.COUNTRY_MANAGER,
    ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
    ROLE_TYPE.COUNTRY_VIEWER,
  ],
  [SERVICE.VDA]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
  ],
  [SERVICE.FIND]: [ROLE_TYPE.GLOBAL_MANAGER, ROLE_TYPE.GLOBAL_VIEWER],
  [SERVICE.MAP_DECOR]: [
    ROLE_TYPE.GLOBAL_MANAGER,
    ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
    ROLE_TYPE.GLOBAL_VIEWER,
  ],
  [SERVICE.QR_LOUNGE]: [ROLE_TYPE.GLOBAL_MANAGER, ROLE_TYPE.GLOBAL_VIEWER],
};

export const SERVICE_LIST: Record<string, string> = {
  assets: 'Assets',
  gse: 'GSE',
  plugin: 'Plugin',
  recom: 'Recommendation',
  routine: 'Routine',
  sd: 'Supported Device',
  vda: 'Virtual Device App',
  find: 'Find NMS',
  explore: 'Explore (non-Retail)',
  explore_retail: 'Explore (Retail)',
  qr_lounge: 'QR Lounge Web',
};

export const SERVICE_LIST_ARRAY = [
  { value: 'assets', label: 'Assets' },
  { value: 'gse', label: 'GSE' },
  { value: 'plugin', label: 'Plugin' },
  { value: 'recom', label: 'Recommendation' },
  { value: 'routine', label: 'Routine' },
  { value: 'sd', label: 'Supported Device' },
  { value: 'vda', label: 'Virtual Device App' },
  { value: 'find', label: 'Find NMS' },
  { value: 'explore', label: 'Explore (non-Retail)' },
  { value: 'explore_retail', label: 'Explore (Retail)' },
  { value: 'qr_lounge', label: 'QR Lounge Web' },
];

export const SERVICE_ROLES: Record<string, string> = {
  assets: 'assets',
  gse: 'gse',
  plugin: 'plugin',
  recom: 'recom',
  routine: 'routine',
  sd: 'sd',
  vda: 'vda',
  explore: 'explore',
  find: 'find',
  explore_retail: 'explore_retail',
  map_decor: 'map_decor',
  qr_lounge: 'qr_lounge',
};

export const SERVICE_MAPPING: Record<string, string> = {
  recommendation: 'recom',
  mall: 'sd',
  'qr-lounge': 'qr_lounge',
};

export const STATE_MAPPING = {
  draft: 'draft',
  approval_requested: 'approval_requested',
  published: 'published',
  archived: 'archived',
};

export const COUNTRY_LIST = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AG', name: 'Antigua and Barbuda' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BS', name: 'Bahamas (the)' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia (Plurinational State of)' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BR', name: 'Brazil' },
  { code: 'IO', name: 'British Indian Ocean Territory (the)' },
  { code: 'BN', name: 'Brunei Darussalam' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'CV', name: 'Cabo Verde' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CA', name: 'Canada' },
  { code: 'KY', name: 'Cayman Islands (the)' },
  { code: 'CF', name: 'Central African Republic (the)' },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CC', name: 'Cocos (Keeling) Islands (the)' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros (the)' },
  { code: 'CD', name: 'Congo (the Democratic Republic of the)' },
  { code: 'CG', name: 'Congo (the)' },
  { code: 'CK', name: 'Cook Islands (the)' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czechia' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic (the)' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estonia' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FK', name: 'Falkland Islands (the) [Malvinas]' },
  { code: 'FO', name: 'Faroe Islands (the)' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'TF', name: 'French Southern Territories (the)' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia (the)' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HM', name: 'Heard Island and McDonald Islands' },
  { code: 'VA', name: 'Holy See (the)' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IR', name: 'Iran (Islamic Republic of)' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KP', name: "Korea (the Democratic People's Republic of)" },
  { code: 'KR', name: 'Korea (the Republic of)' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: "Lao People's Democratic Republic (the)" },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MO', name: 'Macao' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands (the)' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Mexico' },
  { code: 'FM', name: 'Micronesia (Federated States of)' },
  { code: 'MD', name: 'Moldova (the Republic of)' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands (the)' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger (the)' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'MP', name: 'Northern Mariana Islands (the)' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PS', name: 'Palestine, State of' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines (the)' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russian Federation (the)' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'RE', name: 'Réunion' },
  { code: 'BL', name: 'Saint Barthélemy' },
  { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'MF', name: 'Saint Martin (French part)' },
  { code: 'PM', name: 'Saint Pierre and Miquelon' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SX', name: 'Sint Maarten (Dutch part)' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SD', name: 'Sudan (the)' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'SY', name: 'Syrian Arab Republic (the)' },
  { code: 'TW', name: 'Taiwan (Province of China)' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania, the United Republic of' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks and Caicos Islands (the)' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TR', name: 'Türkiye' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates (the)' },
  {
    code: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
  },
  { code: 'UM', name: 'United States Minor Outlying Islands (the)' },
  { code: 'US', name: 'United States of America (the)' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
  { code: 'VN', name: 'Viet Nam' },
  { code: 'VG', name: 'Virgin Islands (British)' },
  { code: 'VI', name: 'Virgin Islands (U.S.)' },
  { code: 'WF', name: 'Wallis and Futuna' },
  { code: 'EH', name: 'Western Sahara*' },
  { code: 'YE', name: 'Yemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
  { code: 'AX', name: 'Åland Islands' },
];

export const STATUS_LABEL: any = {
  all: 'All',
  draft: 'Draft',
  inactive: 'Inactive',
  requested: 'Requested',
  approval: 'Requested',
  pending_approval: 'Requested',
  approval_requested: 'Requested',
  pending: 'Requested',
  approved: 'Approved',
  paused: 'Paused',
  published: 'Published',
  active: 'Active',
  completed: 'Completed',
  archived: 'Archived',
  active_running: 'Active Running',
  enabled: 'Enabled',
  disabled: 'Disabled',
  page: 'Page',
  'how to use': 'How To Use',
  video: 'Video',
  'st find': 'ST Find',
  withdraw: 'Withdraw',
  failed: 'Failed',
  rejected: 'Rejected',
};

export const statusColorBackground = {
  DRAFT: '#FFFFFF',
  INACTIVE: '#767B9B',
  PENDING_APPROVAL: '#ACD8ED',
  APPROVAL_REQUESTED: '#ACD8ED',
  PENDING: '#ACD8ED',
  REQUESTED: '#ACD8ED',
  APPROVED: '#B2E0DD',
  PAUSED: '#B7E9CD',
  PUBLISHED: '#BCF2BD',
  ACTIVE: '#BCF2BD',
  ACTIVE_RUNNING: '#BCF2BD',
  COMPLETED: '#C1FAAD',
  ARCHIVED: '#313131',
  ENABLED: '#BCF2BD',
  DISABLED: '#B8B8B8',
  WITHDRAW: '#76996A',
  SUCCEED: '#BCF2BD',
  FAILED: '#FF0000',
  REJECTED: '#767B9B',
};

export const statusColor = {
  DRAFT: '#313131',
  INACTIVE: '#FFFFFF',
  PENDING_APPROVAL: '#313131',
  APPROVAL_REQUESTED: '#313131',
  PENDING: '#313131',
  REQUESTED: '#313131',
  APPROVED: '#313131',
  PAUSED: '#313131',
  PUBLISHED: '#313131',
  ACTIVE: '#313131',
  ACTIVE_RUNNING: '#313131',
  COMPLETED: '#313131',
  ARCHIVED: '#FFFFFF',
  ENABLED: '#313131',
  DISABLED: '#313131',
  SUCCEED: '#313131',
  FAILED: '#FFFFFF',
  REJECTED: '#FFFFFF',
};

export const domainToKey = {
  'samsung.com': 's',
  'partner.samsung.com': 'p',
  'smartthings.com': 'st',
  'sea.samsung.com': 'sea',
  'ea.corp.samsungelectronics.net': 'eac',
  'miracom.co.kr': 'mira',
  'stage.partner.samsung.com': 'stgp',
  'partner.sec.co.kr': 'psec',
  'gra-ph.com': 'g',
  'secui.com': 'sc',
  'gmail.com': 'gm',
  'iit.du.ac.bd': 'it',
};

export const keyToDomain = {
  s: 'samsung.com',
  p: 'partner.samsung.com',
  st: 'smartthings.com',
  sea: 'sea.samsung.com',
  eac: 'ea.corp.samsungelectronics.net',
  mira: 'miracom.co.kr',
  stgp: 'stage.partner.samsung.com',
  psec: 'partner.sec.co.kr',
  g: 'gra-ph.com',
  sc: 'secui.com',
  gm: 'gmail.com',
  it: 'iit.du.ac.bd',
};

export const COUNTRY_MAP: any = {
  AFG: {
    label: 'Afghanistan',
    value: 'AFG',
    value2: 'AF',
  },
  ALB: {
    label: 'Albania',
    value: 'ALB',
    value2: 'AL',
  },
  DZA: {
    label: 'Algeria',
    value: 'DZA',
    value2: 'DZ',
  },
  ASM: {
    label: 'American Samoa',
    value: 'ASM',
    value2: 'AS',
  },
  AND: {
    label: 'Andorra',
    value: 'AND',
    value2: 'AD',
  },
  AGO: {
    label: 'Angola',
    value: 'AGO',
    value2: 'AO',
  },
  AIA: {
    label: 'Anguilla',
    value: 'AIA',
    value2: 'AI',
  },
  ATA: {
    label: 'Antarctica',
    value: 'ATA',
    value2: 'AQ',
  },
  ARG: {
    label: 'Argentina',
    value: 'ARG',
    value2: 'AR',
  },
  ARM: {
    label: 'Armenia',
    value: 'ARM',
    value2: 'AM',
  },
  ABW: {
    label: 'Aruba',
    value: 'ABW',
    value2: 'AW',
  },
  AUS: {
    label: 'Australia',
    value: 'AUS',
    value2: 'AU',
  },
  AUT: {
    label: 'Austria',
    value: 'AUT',
    value2: 'AT',
  },
  AZE: {
    label: 'Azerbaijan',
    value: 'AZE',
    value2: 'AZ',
  },
  BHS: {
    label: 'Bahamas',
    value: 'BHS',
    value2: 'BS',
  },
  BHR: {
    label: 'Bahrain',
    value: 'BHR',
    value2: 'BH',
  },
  BGD: {
    label: 'Bangladesh',
    value: 'BGD',
    value2: 'BD',
  },
  BRB: {
    label: 'Barbados',
    value: 'BRB',
    value2: 'BB',
  },
  BLR: {
    label: 'Belarus',
    value: 'BLR',
    value2: 'BY',
  },
  BEL: {
    label: 'Belgium',
    value: 'BEL',
    value2: 'BE',
  },
  BLZ: {
    label: 'Belize',
    value: 'BLZ',
    value2: 'BZ',
  },
  BEN: {
    label: 'Benin',
    value: 'BEN',
    value2: 'BJ',
  },
  BMU: {
    label: 'Bermuda',
    value: 'BMU',
    value2: 'BM',
  },
  BTN: {
    label: 'Bhutan',
    value: 'BTN',
    value2: 'BT',
  },
  BOL: {
    label: 'Bolivia',
    value: 'BOL',
    value2: 'BO',
  },
  BIH: {
    label: 'Bosnia and Herzegovina',
    value: 'BIH',
    value2: 'BA',
  },
  BWA: {
    label: 'Botswana',
    value: 'BWA',
    value2: 'BW',
  },
  BVT: {
    label: 'Bouvet Island',
    value: 'BVT',
    value2: 'BV',
  },
  BRA: {
    label: 'Brazil',
    value: 'BRA',
    value2: 'BR',
  },
  IOT: {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
    value2: 'IO',
  },
  VGB: {
    label: 'British Virgin Islands',
    value: 'VGB',
    value2: 'VG',
  },
  BRN: {
    label: 'Brunei Darussalam',
    value: 'BRN',
    value2: 'BN',
  },
  BGR: {
    label: 'Bulgaria',
    value: 'BGR',
    value2: 'BG',
  },
  BFA: {
    label: 'Burkina Faso',
    value: 'BFA',
    value2: 'BF',
  },
  BDI: {
    label: 'Burundi',
    value: 'BDI',
    value2: 'BI',
  },
  CPV: {
    label: 'Cabo Verde',
    value: 'CPV',
    value2: 'CV',
  },
  KHM: {
    label: 'Cambodia',
    value: 'KHM',
    value2: 'KH',
  },
  CMR: {
    label: 'Cameroon',
    value: 'CMR',
    value2: 'CM',
  },
  CAN: {
    label: 'Canada',
    value: 'CAN',
    value2: 'CA',
  },
  CYM: {
    label: 'Cayman Islands',
    value: 'CYM',
    value2: 'KY',
  },
  CAF: {
    label: 'Central African Republic',
    value: 'CAF',
    value2: 'CF',
  },
  TCD: {
    label: 'Chad',
    value: 'TCD',
    value2: 'TD',
  },
  CHL: {
    label: 'Chile',
    value: 'CHL',
    value2: 'CL',
  },
  CHN: {
    label: 'China',
    value: 'CHN',
    value2: 'CN',
  },
  CXR: {
    label: 'Christmas Island',
    value: 'CXR',
    value2: 'CX',
  },
  CCK: {
    label: 'Cocos Islands',
    value: 'CCK',
    value2: 'CC',
  },
  COL: {
    label: 'Colombia',
    value: 'COL',
    value2: 'CO',
  },
  COM: {
    label: 'Comoros',
    value: 'COM',
    value2: 'KM',
  },
  COK: {
    label: 'Cook Islands',
    value: 'COK',
    value2: 'CK',
  },
  CRI: {
    label: 'Costa Rica',
    value: 'CRI',
    value2: 'CR',
  },
  HRV: {
    label: 'Croatia',
    value: 'HRV',
    value2: 'HR',
  },
  CUB: {
    label: 'Cuba',
    value: 'CUB',
    value2: 'CU',
  },
  CUW: {
    label: 'Curaçao',
    value: 'CUW',
    value2: 'CW',
  },
  CYP: {
    label: 'Cyprus',
    value: 'CYP',
    value2: 'CY',
  },
  CZE: {
    label: 'Czechia',
    value: 'CZE',
    value2: 'CZ',
  },
  CIV: {
    label: "Côte d'Ivoire",
    value: 'CIV',
    value2: 'CI',
  },
  COD: {
    label: 'Democratic Republic of the Congo',
    value: 'COD',
    value2: 'CD',
  },
  DNK: {
    label: 'Denmark',
    value: 'DNK',
    value2: 'DK',
  },
  DJI: {
    label: 'Djibouti',
    value: 'DJI',
    value2: 'DJ',
  },
  DMA: {
    label: 'Dominica',
    value: 'DMA',
    value2: 'DM',
  },
  DOM: {
    label: 'Dominican Republic',
    value: 'DOM',
    value2: 'DO',
  },
  ECU: {
    label: 'Ecuador',
    value: 'ECU',
    value2: 'EC',
  },
  EGY: {
    label: 'Egypt',
    value: 'EGY',
    value2: 'EG',
  },
  SLV: {
    label: 'El Salvador',
    value: 'SLV',
    value2: 'SV',
  },
  GNQ: {
    label: 'Equatorial Guinea',
    value: 'GNQ',
    value2: 'GQ',
  },
  ERI: {
    label: 'Eritrea',
    value: 'ERI',
    value2: 'ER',
  },
  EST: {
    label: 'Estonia',
    value: 'EST',
    value2: 'EE',
  },
  SWZ: {
    label: 'Eswatini',
    value: 'SWZ',
    value2: 'SZ',
  },
  ETH: {
    label: 'Ethiopia',
    value: 'ETH',
    value2: 'ET',
  },
  FLK: {
    label: 'Falkland Islands',
    value: 'FLK',
    value2: 'FK',
  },
  FRO: {
    label: 'Faroe Islands',
    value: 'FRO',
    value2: 'FO',
  },
  FJI: {
    label: 'Fiji',
    value: 'FJI',
    value2: 'FJ',
  },
  FIN: {
    label: 'Finland',
    value: 'FIN',
    value2: 'FI',
  },
  FRA: {
    label: 'France',
    value: 'FRA',
    value2: 'FR',
  },
  GUF: {
    label: 'French Guiana',
    value: 'GUF',
    value2: 'GF',
  },
  PYF: {
    label: 'French Polynesia',
    value: 'PYF',
    value2: 'PF',
  },
  ATF: {
    label: 'French Southern Territories',
    value: 'ATF',
    value2: 'TF',
  },
  GAB: {
    label: 'Gabon',
    value: 'GAB',
    value2: 'GA',
  },
  GMB: {
    label: 'Gambia',
    value: 'GMB',
    value2: 'GM',
  },
  GEO: {
    label: 'Georgia',
    value: 'GEO',
    value2: 'GE',
  },
  DEU: {
    label: 'Germany',
    value: 'DEU',
    value2: 'DE',
  },
  GHA: {
    label: 'Ghana',
    value: 'GHA',
    value2: 'GH',
  },
  GIB: {
    label: 'Gibraltar',
    value: 'GIB',
    value2: 'GI',
  },
  GRC: {
    label: 'Greece',
    value: 'GRC',
    value2: 'GR',
  },
  GRL: {
    label: 'Greenland',
    value: 'GRL',
    value2: 'GL',
  },
  GRD: {
    label: 'Grenada',
    value: 'GRD',
    value2: 'GD',
  },
  GLP: {
    label: 'Guadeloupe',
    value: 'GLP',
    value2: 'GP',
  },
  GUM: {
    label: 'Guam',
    value: 'GUM',
    value2: 'GU',
  },
  GTM: {
    label: 'Guatemala',
    value: 'GTM',
    value2: 'GT',
  },
  GGY: {
    label: 'Guernsey',
    value: 'GGY',
    value2: 'GG',
  },
  GIN: {
    label: 'Guinea',
    value: 'GIN',
    value2: 'GN',
  },
  GNB: {
    label: 'Guinea-Bissau',
    value: 'GNB',
    value2: 'GW',
  },
  GUY: {
    label: 'Guyana',
    value: 'GUY',
    value2: 'GY',
  },
  HTI: {
    label: 'Haiti',
    value: 'HTI',
    value2: 'HT',
  },
  HND: {
    label: 'Honduras',
    value: 'HND',
    value2: 'HN',
  },
  HKG: {
    label: 'Hong Kong',
    value: 'HKG',
    value2: 'HK',
  },
  HUN: {
    label: 'Hungary',
    value: 'HUN',
    value2: 'HU',
  },
  ISL: {
    label: 'Iceland',
    value: 'ISL',
    value2: 'IS',
  },
  IND: {
    label: 'India',
    value: 'IND',
    value2: 'IN',
  },
  IDN: {
    label: 'Indonesia',
    value: 'IDN',
    value2: 'ID',
  },
  IRN: {
    label: 'Iran',
    value: 'IRN',
    value2: 'IR',
  },
  IRQ: {
    label: 'Iraq',
    value: 'IRQ',
    value2: 'IQ',
  },
  IRL: {
    label: 'Ireland',
    value: 'IRL',
    value2: 'IE',
  },
  IMN: {
    label: 'Isle of Man',
    value: 'IMN',
    value2: 'IM',
  },
  ISR: {
    label: 'Israel',
    value: 'ISR',
    value2: 'IL',
  },
  ITA: {
    label: 'Italy',
    value: 'ITA',
    value2: 'IT',
  },
  JAM: {
    label: 'Jamaica',
    value: 'JAM',
    value2: 'JM',
  },
  JPN: {
    label: 'Japan',
    value: 'JPN',
    value2: 'JP',
  },
  JEY: {
    label: 'Jersey',
    value: 'JEY',
    value2: 'JE',
  },
  JOR: {
    label: 'Jordan',
    value: 'JOR',
    value2: 'JO',
  },
  KAZ: {
    label: 'Kazakhstan',
    value: 'KAZ',
    value2: 'KZ',
  },
  KEN: {
    label: 'Kenya',
    value: 'KEN',
    value2: 'KE',
  },
  KIR: {
    label: 'Kiribati',
    value: 'KIR',
    value2: 'KI',
  },
  KWT: {
    label: 'Kuwait',
    value: 'KWT',
    value2: 'KW',
  },
  KGZ: {
    label: 'Kyrgyzstan',
    value: 'KGZ',
    value2: 'KG',
  },
  LAO: {
    label: 'Laos',
    value: 'LAO',
    value2: 'LA',
  },
  LVA: {
    label: 'Latvia',
    value: 'LVA',
    value2: 'LV',
  },
  LBN: {
    label: 'Lebanon',
    value: 'LBN',
    value2: 'LB',
  },
  LSO: {
    label: 'Lesotho',
    value: 'LSO',
    value2: 'LS',
  },
  LBR: {
    label: 'Liberia',
    value: 'LBR',
    value2: 'LR',
  },
  LBY: {
    label: 'Libya',
    value: 'LBY',
    value2: 'LY',
  },
  LIE: {
    label: 'Liechtenstein',
    value: 'LIE',
    value2: 'LI',
  },
  LTU: {
    label: 'Lithuania',
    value: 'LTU',
    value2: 'LT',
  },
  LUX: {
    label: 'Luxembourg',
    value: 'LUX',
    value2: 'LU',
  },
  MAC: {
    label: 'Macao',
    value: 'MAC',
    value2: 'MO',
  },
  MKD: {
    label: 'Macedonia',
    value: 'MKD',
    value2: 'MK',
  },
  MDG: {
    label: 'Madagascar',
    value: 'MDG',
    value2: 'MG',
  },
  MWI: {
    label: 'Malawi',
    value: 'MWI',
    value2: 'MW',
  },
  MYS: {
    label: 'Malaysia',
    value: 'MYS',
    value2: 'MY',
  },
  MDV: {
    label: 'Maldives',
    value: 'MDV',
    value2: 'MV',
  },
  MLI: {
    label: 'Mali',
    value: 'MLI',
    value2: 'ML',
  },
  MLT: {
    label: 'Malta',
    value: 'MLT',
    value2: 'MT',
  },
  MHL: {
    label: 'Marshall Islands',
    value: 'MHL',
    value2: 'MH',
  },
  MTQ: {
    label: 'Martinique',
    value: 'MTQ',
    value2: 'MQ',
  },
  MRT: {
    label: 'Mauritania',
    value: 'MRT',
    value2: 'MR',
  },
  MUS: {
    label: 'Mauritius',
    value: 'MUS',
    value2: 'MU',
  },
  MYT: {
    label: 'Mayotte',
    value: 'MYT',
    value2: 'YT',
  },
  MEX: {
    label: 'Mexico',
    value: 'MEX',
    value2: 'MX',
  },
  FSM: {
    label: 'Micronesia',
    value: 'FSM',
    value2: 'FM',
  },
  MDA: {
    label: 'Moldova',
    value: 'MDA',
    value2: 'MD',
  },
  MCO: {
    label: 'Monaco',
    value: 'MCO',
    value2: 'MC',
  },
  MNG: {
    label: 'Mongolia',
    value: 'MNG',
    value2: 'MN',
  },
  MNE: {
    label: 'Montenegro',
    value: 'MNE',
    value2: 'ME',
  },
  MSR: {
    label: 'Montserrat',
    value: 'MSR',
    value2: 'MS',
  },
  MAR: {
    label: 'Morocco',
    value: 'MAR',
    value2: 'MA',
  },
  MOZ: {
    label: 'Mozambique',
    value: 'MOZ',
    value2: 'MZ',
  },
  MMR: {
    label: 'Myanmar',
    value: 'MMR',
    value2: 'MM',
  },
  NAM: {
    label: 'Namibia',
    value: 'NAM',
    value2: 'NA',
  },
  NRU: {
    label: 'Nauru',
    value: 'NRU',
    value2: 'NR',
  },
  NPL: {
    label: 'Nepal',
    value: 'NPL',
    value2: 'NP',
  },
  NLD: {
    label: 'Netherlands',
    value: 'NLD',
    value2: 'NL',
  },
  NCL: {
    label: 'New Caledonia',
    value: 'NCL',
    value2: 'NC',
  },
  NZL: {
    label: 'New Zealand',
    value: 'NZL',
    value2: 'NZ',
  },
  NIC: {
    label: 'Nicaragua',
    value: 'NIC',
    value2: 'NI',
  },
  NER: {
    label: 'Niger',
    value: 'NER',
    value2: 'NE',
  },
  NGA: {
    label: 'Nigeria',
    value: 'NGA',
    value2: 'NG',
  },
  NIU: {
    label: 'Niue',
    value: 'NIU',
    value2: 'NU',
  },
  NFK: {
    label: 'Norfolk Island',
    value: 'NFK',
    value2: 'NF',
  },
  PRK: {
    label: 'North Korea',
    value: 'PRK',
    value2: 'KP',
  },
  MNP: {
    label: 'Northern Mariana Islands',
    value: 'MNP',
    value2: 'MP',
  },
  NOR: {
    label: 'Norway',
    value: 'NOR',
    value2: 'NO',
  },
  OMN: {
    label: 'Oman',
    value: 'OMN',
    value2: 'OM',
  },
  PAK: {
    label: 'Pakistan',
    value: 'PAK',
    value2: 'PK',
  },
  PLW: {
    label: 'Palau',
    value: 'PLW',
    value2: 'PW',
  },
  PSE: {
    label: 'Palestinian Territory',
    value: 'PSE',
    value2: 'PS',
  },
  PAN: {
    label: 'Panama',
    value: 'PAN',
    value2: 'PA',
  },
  PNG: {
    label: 'Papua New Guinea',
    value: 'PNG',
    value2: 'PG',
  },
  PRY: {
    label: 'Paraguay',
    value: 'PRY',
    value2: 'PY',
  },
  PER: {
    label: 'Peru',
    value: 'PER',
    value2: 'PE',
  },
  PHL: {
    label: 'Philippines',
    value: 'PHL',
    value2: 'PH',
  },
  PCN: {
    label: 'Pitcairn',
    value: 'PCN',
    value2: 'PN',
  },
  POL: {
    label: 'Poland',
    value: 'POL',
    value2: 'PL',
  },
  PRT: {
    label: 'Portugal',
    value: 'PRT',
    value2: 'PT',
  },
  PRI: {
    label: 'Puerto Rico',
    value: 'PRI',
    value2: 'PR',
  },
  QAT: {
    label: 'Qatar',
    value: 'QAT',
    value2: 'QA',
  },
  COG: {
    label: 'Republic of the Congo',
    value: 'COG',
    value2: 'CG',
  },
  ROU: {
    label: 'Romania',
    value: 'ROU',
    value2: 'RO',
  },
  RUS: {
    label: 'Russia',
    value: 'RUS',
    value2: 'RU',
  },
  RWA: {
    label: 'Rwanda',
    value: 'RWA',
    value2: 'RW',
  },
  REU: {
    label: 'Réunion',
    value: 'REU',
    value2: 'RE',
  },
  BLM: {
    label: 'Saint Barthélemy',
    value: 'BLM',
    value2: 'BL',
  },
  SHN: {
    label: 'Saint Helena',
    value: 'SHN',
    value2: 'SH',
  },
  KNA: {
    label: 'Saint Kitts and Nevis',
    value: 'KNA',
    value2: 'KN',
  },
  LCA: {
    label: 'Saint Lucia',
    value: 'LCA',
    value2: 'LC',
  },
  MAF: {
    label: 'Saint Martin',
    value: 'MAF',
    value2: 'MF',
  },
  WSM: {
    label: 'Samoa',
    value: 'WSM',
    value2: 'WS',
  },
  SMR: {
    label: 'San Marino',
    value: 'SMR',
    value2: 'SM',
  },
  SAU: {
    label: 'Saudi Arabia',
    value: 'SAU',
    value2: 'SA',
  },
  SEN: {
    label: 'Senegal',
    value: 'SEN',
    value2: 'SN',
  },
  SRB: {
    label: 'Serbia',
    value: 'SRB',
    value2: 'RS',
  },
  SYC: {
    label: 'Seychelles',
    value: 'SYC',
    value2: 'SC',
  },
  SLE: {
    label: 'Sierra Leone',
    value: 'SLE',
    value2: 'SL',
  },
  SGP: {
    label: 'Singapore',
    value: 'SGP',
    value2: 'SG',
  },
  SXM: {
    label: 'Sint Maarten',
    value: 'SXM',
    value2: 'SX',
  },
  SVK: {
    label: 'Slovakia',
    value: 'SVK',
    value2: 'SK',
  },
  SVN: {
    label: 'Slovenia',
    value: 'SVN',
    value2: 'SI',
  },
  SLB: {
    label: 'Solomon Islands',
    value: 'SLB',
    value2: 'SB',
  },
  SOM: {
    label: 'Somalia',
    value: 'SOM',
    value2: 'SO',
  },
  ZAF: {
    label: 'South Africa',
    value: 'ZAF',
    value2: 'ZA',
  },
  KOR: {
    label: 'South Korea',
    value: 'KOR',
    value2: 'KR',
  },
  SSD: {
    label: 'South Sudan',
    value: 'SSD',
    value2: 'SS',
  },
  ESP: {
    label: 'Spain',
    value: 'ESP',
    value2: 'ES',
  },
  LKA: {
    label: 'Sri Lanka',
    value: 'LKA',
    value2: 'LK',
  },
  SDN: {
    label: 'Sudan',
    value: 'SDN',
    value2: 'SD',
  },
  SUR: {
    label: 'Suriname',
    value: 'SUR',
    value2: 'SR',
  },
  SWE: {
    label: 'Sweden',
    value: 'SWE',
    value2: 'SE',
  },
  CHE: {
    label: 'Switzerland',
    value: 'CHE',
    value2: 'CH',
  },
  SYR: {
    label: 'Syria',
    value: 'SYR',
    value2: 'SY',
  },
  TWN: {
    label: 'Taiwan',
    value: 'TWN',
    value2: 'TW',
  },
  TJK: {
    label: 'Tajikistan',
    value: 'TJK',
    value2: 'TJ',
  },
  TZA: {
    label: 'Tanzania',
    value: 'TZA',
    value2: 'TZ',
  },
  THA: {
    label: 'Thailand',
    value: 'THA',
    value2: 'TH',
  },
  TLS: {
    label: 'Timor-Leste',
    value: 'TLS',
    value2: 'TL',
  },
  TGO: {
    label: 'Togo',
    value: 'TGO',
    value2: 'TG',
  },
  TKL: {
    label: 'Tokelau',
    value: 'TKL',
    value2: 'TK',
  },
  TON: {
    label: 'Tonga',
    value: 'TON',
    value2: 'TO',
  },
  TTO: {
    label: 'Trinidad and Tobago',
    value: 'TTO',
    value2: 'TT',
  },
  TUN: {
    label: 'Tunisia',
    value: 'TUN',
    value2: 'TN',
  },
  TUR: {
    label: 'Turkey',
    value: 'TUR',
    value2: 'TR',
  },
  TKM: {
    label: 'Turkmenistan',
    value: 'TKM',
    value2: 'TM',
  },
  TUV: {
    label: 'Tuvalu',
    value: 'TUV',
    value2: 'TV',
  },
  VIR: {
    label: 'U.S. Virgin Islands',
    value: 'VIR',
    value2: 'VI',
  },
  UGA: {
    label: 'Uganda',
    value: 'UGA',
    value2: 'UG',
  },
  UKR: {
    label: 'Ukraine',
    value: 'UKR',
    value2: 'UA',
  },
  ARE: {
    label: 'United Arab Emirates',
    value: 'ARE',
    value2: 'AE',
  },
  GBR: {
    label: 'United Kingdom',
    value: 'GBR',
    value2: 'GB',
  },
  USA: {
    label: 'United States',
    value: 'USA',
    value2: 'US',
  },
  URY: {
    label: 'Uruguay',
    value: 'URY',
    value2: 'UY',
  },
  UZB: {
    label: 'Uzbekistan',
    value: 'UZB',
    value2: 'UZ',
  },
  VUT: {
    label: 'Vanuatu',
    value: 'VUT',
    value2: 'VU',
  },
  VEN: {
    label: 'Venezuela',
    value: 'VEN',
    value2: 'VE',
  },
  VNM: {
    label: 'Vietnam',
    value: 'VNM',
    value2: 'VN',
  },
  WLF: {
    label: 'Wallis and Futuna',
    value: 'WLF',
    value2: 'WF',
  },
  ESH: {
    label: 'Western Sahara',
    value: 'ESH',
    value2: 'EH',
  },
  YEM: {
    label: 'Yemen',
    value: 'YEM',
    value2: 'YE',
  },
  ZMB: {
    label: 'Zambia',
    value: 'ZMB',
    value2: 'ZM',
  },
  ZWE: {
    label: 'Zimbabwe',
    value: 'ZWE',
    value2: 'ZW',
  },
  ALA: {
    label: 'Åland Islands',
    value: 'ALA',
    value2: 'AX',
  },
};

export const COUNTRY_MAP2 = {
  AF: {
    label: 'Afghanistan',
    value: 'AFG',
    value2: 'AF',
  },
  AL: {
    label: 'Albania',
    value: 'ALB',
    value2: 'AL',
  },
  DZ: {
    label: 'Algeria',
    value: 'DZA',
    value2: 'DZ',
  },
  AS: {
    label: 'American Samoa',
    value: 'ASM',
    value2: 'AS',
  },
  AN: {
    label: 'Andorra',
    value: 'AND',
    value2: 'AD',
  },
  AG: {
    label: 'Angola',
    value: 'AGO',
    value2: 'AO',
  },
  AI: {
    label: 'Anguilla',
    value: 'AIA',
    value2: 'AI',
  },
  AQ: {
    label: 'Antarctica',
    value: 'ATA',
    value2: 'AQ',
  },
  AR: {
    label: 'Argentina',
    value: 'ARG',
    value2: 'AR',
  },
  AM: {
    label: 'Armenia',
    value: 'ARM',
    value2: 'AM',
  },
  AB: {
    label: 'Aruba',
    value: 'ABW',
    value2: 'AW',
  },
  AU: {
    label: 'Australia',
    value: 'AUS',
    value2: 'AU',
  },
  AT: {
    label: 'Austria',
    value: 'AUT',
    value2: 'AT',
  },
  AZ: {
    label: 'Azerbaijan',
    value: 'AZE',
    value2: 'AZ',
  },
  BS: {
    label: 'Bahamas',
    value: 'BHS',
    value2: 'BS',
  },
  BH: {
    label: 'Bahrain',
    value: 'BHR',
    value2: 'BH',
  },
  BD: {
    label: 'Bangladesh',
    value: 'BGD',
    value2: 'BD',
  },
  BB: {
    label: 'Barbados',
    value: 'BRB',
    value2: 'BB',
  },
  BY: {
    label: 'Belarus',
    value: 'BLR',
    value2: 'BY',
  },
  BE: {
    label: 'Belgium',
    value: 'BEL',
    value2: 'BE',
  },
  BZ: {
    label: 'Belize',
    value: 'BLZ',
    value2: 'BZ',
  },
  BJ: {
    label: 'Benin',
    value: 'BEN',
    value2: 'BJ',
  },
  BM: {
    label: 'Bermuda',
    value: 'BMU',
    value2: 'BM',
  },
  BT: {
    label: 'Bhutan',
    value: 'BTN',
    value2: 'BT',
  },
  BO: {
    label: 'Bolivia',
    value: 'BOL',
    value2: 'BO',
  },
  BA: {
    label: 'Bosnia and Herzegovina',
    value: 'BIH',
    value2: 'BA',
  },
  BW: {
    label: 'Botswana',
    value: 'BWA',
    value2: 'BW',
  },
  BV: {
    label: 'Bouvet Island',
    value: 'BVT',
    value2: 'BV',
  },
  BR: {
    label: 'Brazil',
    value: 'BRA',
    value2: 'BR',
  },
  IO: {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
    value2: 'IO',
  },
  VG: {
    label: 'British Virgin Islands',
    value: 'VGB',
    value2: 'VG',
  },
  BN: {
    label: 'Brunei Darussalam',
    value: 'BRN',
    value2: 'BN',
  },
  BG: {
    label: 'Bulgaria',
    value: 'BGR',
    value2: 'BG',
  },
  BF: {
    label: 'Burkina Faso',
    value: 'BFA',
    value2: 'BF',
  },
  BI: {
    label: 'Burundi',
    value: 'BDI',
    value2: 'BI',
  },
  CP: {
    label: 'Cabo Verde',
    value: 'CPV',
    value2: 'CV',
  },
  KH: {
    label: 'Cambodia',
    value: 'KHM',
    value2: 'KH',
  },
  CM: {
    label: 'Cameroon',
    value: 'CMR',
    value2: 'CM',
  },
  CA: {
    label: 'Canada',
    value: 'CAN',
    value2: 'CA',
  },
  KY: {
    label: 'Cayman Islands',
    value: 'CYM',
    value2: 'KY',
  },
  CF: {
    label: 'Central African Republic',
    value: 'CAF',
    value2: 'CF',
  },
  TC: {
    label: 'Chad',
    value: 'TCD',
    value2: 'TD',
  },
  CL: {
    label: 'Chile',
    value: 'CHL',
    value2: 'CL',
  },
  CN: {
    label: 'China',
    value: 'CHN',
    value2: 'CN',
  },
  CX: {
    label: 'Christmas Island',
    value: 'CXR',
    value2: 'CX',
  },
  CC: {
    label: 'Cocos Islands',
    value: 'CCK',
    value2: 'CC',
  },
  CO: {
    label: 'Colombia',
    value: 'COL',
    value2: 'CO',
  },
  KM: {
    label: 'Comoros',
    value: 'COM',
    value2: 'KM',
  },
  CK: {
    label: 'Cook Islands',
    value: 'COK',
    value2: 'CK',
  },
  CR: {
    label: 'Costa Rica',
    value: 'CRI',
    value2: 'CR',
  },
  HR: {
    label: 'Croatia',
    value: 'HRV',
    value2: 'HR',
  },
  CU: {
    label: 'Cuba',
    value: 'CUB',
    value2: 'CU',
  },
  CW: {
    label: 'Curaçao',
    value: 'CUW',
    value2: 'CW',
  },
  CY: {
    label: 'Cyprus',
    value: 'CYP',
    value2: 'CY',
  },
  CZ: {
    label: 'Czechia',
    value: 'CZE',
    value2: 'CZ',
  },
  CI: {
    label: "Côte d'Ivoire",
    value: 'CIV',
    value2: 'CI',
  },
  CD: {
    label: 'Democratic Republic of the Congo',
    value: 'COD',
    value2: 'CD',
  },
  DN: {
    label: 'Denmark',
    value: 'DNK',
    value2: 'DK',
  },
  DJ: {
    label: 'Djibouti',
    value: 'DJI',
    value2: 'DJ',
  },
  DM: {
    label: 'Dominica',
    value: 'DMA',
    value2: 'DM',
  },
  DO: {
    label: 'Dominican Republic',
    value: 'DOM',
    value2: 'DO',
  },
  EC: {
    label: 'Ecuador',
    value: 'ECU',
    value2: 'EC',
  },
  EG: {
    label: 'Egypt',
    value: 'EGY',
    value2: 'EG',
  },
  SV: {
    label: 'El Salvador',
    value: 'SLV',
    value2: 'SV',
  },
  GQ: {
    label: 'Equatorial Guinea',
    value: 'GNQ',
    value2: 'GQ',
  },
  ER: {
    label: 'Eritrea',
    value: 'ERI',
    value2: 'ER',
  },
  EE: {
    label: 'Estonia',
    value: 'EST',
    value2: 'EE',
  },
  SW: {
    label: 'Eswatini',
    value: 'SWZ',
    value2: 'SZ',
  },
  ET: {
    label: 'Ethiopia',
    value: 'ETH',
    value2: 'ET',
  },
  FL: {
    label: 'Falkland Islands',
    value: 'FLK',
    value2: 'FK',
  },
  FO: {
    label: 'Faroe Islands',
    value: 'FRO',
    value2: 'FO',
  },
  FJ: {
    label: 'Fiji',
    value: 'FJI',
    value2: 'FJ',
  },
  FI: {
    label: 'Finland',
    value: 'FIN',
    value2: 'FI',
  },
  FR: {
    label: 'France',
    value: 'FRA',
    value2: 'FR',
  },
  GF: {
    label: 'French Guiana',
    value: 'GUF',
    value2: 'GF',
  },
  PF: {
    label: 'French Polynesia',
    value: 'PYF',
    value2: 'PF',
  },
  TF: {
    label: 'French Southern Territories',
    value: 'ATF',
    value2: 'TF',
  },
  GA: {
    label: 'Gabon',
    value: 'GAB',
    value2: 'GA',
  },
  GM: {
    label: 'Gambia',
    value: 'GMB',
    value2: 'GM',
  },
  GE: {
    label: 'Georgia',
    value: 'GEO',
    value2: 'GE',
  },
  DE: {
    label: 'Germany',
    value: 'DEU',
    value2: 'DE',
  },
  GH: {
    label: 'Ghana',
    value: 'GHA',
    value2: 'GH',
  },
  GI: {
    label: 'Gibraltar',
    value: 'GIB',
    value2: 'GI',
  },
  GR: {
    label: 'Greece',
    value: 'GRC',
    value2: 'GR',
  },
  GL: {
    label: 'Greenland',
    value: 'GRL',
    value2: 'GL',
  },
  GD: {
    label: 'Grenada',
    value: 'GRD',
    value2: 'GD',
  },
  GP: {
    label: 'Guadeloupe',
    value: 'GLP',
    value2: 'GP',
  },
  GU: {
    label: 'Guam',
    value: 'GUM',
    value2: 'GU',
  },
  GT: {
    label: 'Guatemala',
    value: 'GTM',
    value2: 'GT',
  },
  GG: {
    label: 'Guernsey',
    value: 'GGY',
    value2: 'GG',
  },
  GN: {
    label: 'Guinea',
    value: 'GIN',
    value2: 'GN',
  },
  GW: {
    label: 'Guinea-Bissau',
    value: 'GNB',
    value2: 'GW',
  },
  GY: {
    label: 'Guyana',
    value: 'GUY',
    value2: 'GY',
  },
  HT: {
    label: 'Haiti',
    value: 'HTI',
    value2: 'HT',
  },
  HN: {
    label: 'Honduras',
    value: 'HND',
    value2: 'HN',
  },
  HK: {
    label: 'Hong Kong',
    value: 'HKG',
    value2: 'HK',
  },
  HU: {
    label: 'Hungary',
    value: 'HUN',
    value2: 'HU',
  },
  IS: {
    label: 'Iceland',
    value: 'ISL',
    value2: 'IS',
  },
  IN: {
    label: 'India',
    value: 'IND',
    value2: 'IN',
  },
  ID: {
    label: 'Indonesia',
    value: 'IDN',
    value2: 'ID',
  },
  IR: {
    label: 'Iran',
    value: 'IRN',
    value2: 'IR',
  },
  IQ: {
    label: 'Iraq',
    value: 'IRQ',
    value2: 'IQ',
  },
  IE: {
    label: 'Ireland',
    value: 'IRL',
    value2: 'IE',
  },
  IM: {
    label: 'Isle of Man',
    value: 'IMN',
    value2: 'IM',
  },
  IL: {
    label: 'Israel',
    value: 'ISR',
    value2: 'IL',
  },
  IT: {
    label: 'Italy',
    value: 'ITA',
    value2: 'IT',
  },
  JA: {
    label: 'Jamaica',
    value: 'JAM',
    value2: 'JM',
  },
  JP: {
    label: 'Japan',
    value: 'JPN',
    value2: 'JP',
  },
  JE: {
    label: 'Jersey',
    value: 'JEY',
    value2: 'JE',
  },
  JO: {
    label: 'Jordan',
    value: 'JOR',
    value2: 'JO',
  },
  KA: {
    label: 'Kazakhstan',
    value: 'KAZ',
    value2: 'KZ',
  },
  KE: {
    label: 'Kenya',
    value: 'KEN',
    value2: 'KE',
  },
  KI: {
    label: 'Kiribati',
    value: 'KIR',
    value2: 'KI',
  },
  KW: {
    label: 'Kuwait',
    value: 'KWT',
    value2: 'KW',
  },
  KG: {
    label: 'Kyrgyzstan',
    value: 'KGZ',
    value2: 'KG',
  },
  LA: {
    label: 'Laos',
    value: 'LAO',
    value2: 'LA',
  },
  LV: {
    label: 'Latvia',
    value: 'LVA',
    value2: 'LV',
  },
  LB: {
    label: 'Lebanon',
    value: 'LBN',
    value2: 'LB',
  },
  LS: {
    label: 'Lesotho',
    value: 'LSO',
    value2: 'LS',
  },
  LR: {
    label: 'Liberia',
    value: 'LBR',
    value2: 'LR',
  },
  LY: {
    label: 'Libya',
    value: 'LBY',
    value2: 'LY',
  },
  LI: {
    label: 'Liechtenstein',
    value: 'LIE',
    value2: 'LI',
  },
  LT: {
    label: 'Lithuania',
    value: 'LTU',
    value2: 'LT',
  },
  LU: {
    label: 'Luxembourg',
    value: 'LUX',
    value2: 'LU',
  },
  MO: {
    label: 'Macao',
    value: 'MAC',
    value2: 'MO',
  },
  MK: {
    label: 'Macedonia',
    value: 'MKD',
    value2: 'MK',
  },
  MG: {
    label: 'Madagascar',
    value: 'MDG',
    value2: 'MG',
  },
  MW: {
    label: 'Malawi',
    value: 'MWI',
    value2: 'MW',
  },
  MY: {
    label: 'Malaysia',
    value: 'MYS',
    value2: 'MY',
  },
  MV: {
    label: 'Maldives',
    value: 'MDV',
    value2: 'MV',
  },
  ML: {
    label: 'Mali',
    value: 'MLI',
    value2: 'ML',
  },
  MT: {
    label: 'Malta',
    value: 'MLT',
    value2: 'MT',
  },
  MH: {
    label: 'Marshall Islands',
    value: 'MHL',
    value2: 'MH',
  },
  MQ: {
    label: 'Martinique',
    value: 'MTQ',
    value2: 'MQ',
  },
  MR: {
    label: 'Mauritania',
    value: 'MRT',
    value2: 'MR',
  },
  MU: {
    label: 'Mauritius',
    value: 'MUS',
    value2: 'MU',
  },
  YT: {
    label: 'Mayotte',
    value: 'MYT',
    value2: 'YT',
  },
  MX: {
    label: 'Mexico',
    value: 'MEX',
    value2: 'MX',
  },
  FS: {
    label: 'Micronesia',
    value: 'FSM',
    value2: 'FM',
  },
  MD: {
    label: 'Moldova',
    value: 'MDA',
    value2: 'MD',
  },
  MC: {
    label: 'Monaco',
    value: 'MCO',
    value2: 'MC',
  },
  MN: {
    label: 'Mongolia',
    value: 'MNG',
    value2: 'MN',
  },
  ME: {
    label: 'Montenegro',
    value: 'MNE',
    value2: 'ME',
  },
  MS: {
    label: 'Montserrat',
    value: 'MSR',
    value2: 'MS',
  },
  MA: {
    label: 'Morocco',
    value: 'MAR',
    value2: 'MA',
  },
  MZ: {
    label: 'Mozambique',
    value: 'MOZ',
    value2: 'MZ',
  },
  MM: {
    label: 'Myanmar',
    value: 'MMR',
    value2: 'MM',
  },
  NA: {
    label: 'Namibia',
    value: 'NAM',
    value2: 'NA',
  },
  NR: {
    label: 'Nauru',
    value: 'NRU',
    value2: 'NR',
  },
  NP: {
    label: 'Nepal',
    value: 'NPL',
    value2: 'NP',
  },
  NL: {
    label: 'Netherlands',
    value: 'NLD',
    value2: 'NL',
  },
  NC: {
    label: 'New Caledonia',
    value: 'NCL',
    value2: 'NC',
  },
  NZ: {
    label: 'New Zealand',
    value: 'NZL',
    value2: 'NZ',
  },
  NI: {
    label: 'Nicaragua',
    value: 'NIC',
    value2: 'NI',
  },
  NE: {
    label: 'Niger',
    value: 'NER',
    value2: 'NE',
  },
  NG: {
    label: 'Nigeria',
    value: 'NGA',
    value2: 'NG',
  },
  NU: {
    label: 'Niue',
    value: 'NIU',
    value2: 'NU',
  },
  NF: {
    label: 'Norfolk Island',
    value: 'NFK',
    value2: 'NF',
  },
  KP: {
    label: 'North Korea',
    value: 'PRK',
    value2: 'KP',
  },
  MP: {
    label: 'Northern Mariana Islands',
    value: 'MNP',
    value2: 'MP',
  },
  NO: {
    label: 'Norway',
    value: 'NOR',
    value2: 'NO',
  },
  OM: {
    label: 'Oman',
    value: 'OMN',
    value2: 'OM',
  },
  PK: {
    label: 'Pakistan',
    value: 'PAK',
    value2: 'PK',
  },
  PL: {
    label: 'Palau',
    value: 'PLW',
    value2: 'PW',
  },
  PS: {
    label: 'Palestinian Territory',
    value: 'PSE',
    value2: 'PS',
  },
  PA: {
    label: 'Panama',
    value: 'PAN',
    value2: 'PA',
  },
  PN: {
    label: 'Papua New Guinea',
    value: 'PNG',
    value2: 'PG',
  },
  PY: {
    label: 'Paraguay',
    value: 'PRY',
    value2: 'PY',
  },
  PE: {
    label: 'Peru',
    value: 'PER',
    value2: 'PE',
  },
  PH: {
    label: 'Philippines',
    value: 'PHL',
    value2: 'PH',
  },
  PC: {
    label: 'Pitcairn',
    value: 'PCN',
    value2: 'PN',
  },
  PO: {
    label: 'Poland',
    value: 'POL',
    value2: 'PL',
  },
  PT: {
    label: 'Portugal',
    value: 'PRT',
    value2: 'PT',
  },
  PR: {
    label: 'Puerto Rico',
    value: 'PRI',
    value2: 'PR',
  },
  QA: {
    label: 'Qatar',
    value: 'QAT',
    value2: 'QA',
  },
  CG: {
    label: 'Republic of the Congo',
    value: 'COG',
    value2: 'CG',
  },
  RO: {
    label: 'Romania',
    value: 'ROU',
    value2: 'RO',
  },
  RU: {
    label: 'Russia',
    value: 'RUS',
    value2: 'RU',
  },
  RW: {
    label: 'Rwanda',
    value: 'RWA',
    value2: 'RW',
  },
  RE: {
    label: 'Réunion',
    value: 'REU',
    value2: 'RE',
  },
  BL: {
    label: 'Saint Barthélemy',
    value: 'BLM',
    value2: 'BL',
  },
  SH: {
    label: 'Saint Helena',
    value: 'SHN',
    value2: 'SH',
  },
  KN: {
    label: 'Saint Kitts and Nevis',
    value: 'KNA',
    value2: 'KN',
  },
  LC: {
    label: 'Saint Lucia',
    value: 'LCA',
    value2: 'LC',
  },
  MF: {
    label: 'Saint Martin',
    value: 'MAF',
    value2: 'MF',
  },
  WS: {
    label: 'Samoa',
    value: 'WSM',
    value2: 'WS',
  },
  SM: {
    label: 'San Marino',
    value: 'SMR',
    value2: 'SM',
  },
  SA: {
    label: 'Saudi Arabia',
    value: 'SAU',
    value2: 'SA',
  },
  SN: {
    label: 'Senegal',
    value: 'SEN',
    value2: 'SN',
  },
  RS: {
    label: 'Serbia',
    value: 'SRB',
    value2: 'RS',
  },
  SC: {
    label: 'Seychelles',
    value: 'SYC',
    value2: 'SC',
  },
  SL: {
    label: 'Sierra Leone',
    value: 'SLE',
    value2: 'SL',
  },
  SG: {
    label: 'Singapore',
    value: 'SGP',
    value2: 'SG',
  },
  SX: {
    label: 'Sint Maarten',
    value: 'SXM',
    value2: 'SX',
  },
  SK: {
    label: 'Slovakia',
    value: 'SVK',
    value2: 'SK',
  },
  SI: {
    label: 'Slovenia',
    value: 'SVN',
    value2: 'SI',
  },
  SB: {
    label: 'Solomon Islands',
    value: 'SLB',
    value2: 'SB',
  },
  SO: {
    label: 'Somalia',
    value: 'SOM',
    value2: 'SO',
  },
  ZA: {
    label: 'South Africa',
    value: 'ZAF',
    value2: 'ZA',
  },
  KO: {
    label: 'South Korea',
    value: 'KOR',
    value2: 'KR',
  },
  SS: {
    label: 'South Sudan',
    value: 'SSD',
    value2: 'SS',
  },
  ES: {
    label: 'Spain',
    value: 'ESP',
    value2: 'ES',
  },
  LK: {
    label: 'Sri Lanka',
    value: 'LKA',
    value2: 'LK',
  },
  SD: {
    label: 'Sudan',
    value: 'SDN',
    value2: 'SD',
  },
  SR: {
    label: 'Suriname',
    value: 'SUR',
    value2: 'SR',
  },
  SE: {
    label: 'Sweden',
    value: 'SWE',
    value2: 'SE',
  },
  CH: {
    label: 'Switzerland',
    value: 'CHE',
    value2: 'CH',
  },
  SY: {
    label: 'Syria',
    value: 'SYR',
    value2: 'SY',
  },
  TW: {
    label: 'Taiwan',
    value: 'TWN',
    value2: 'TW',
  },
  TJ: {
    label: 'Tajikistan',
    value: 'TJK',
    value2: 'TJ',
  },
  TZ: {
    label: 'Tanzania',
    value: 'TZA',
    value2: 'TZ',
  },
  TH: {
    label: 'Thailand',
    value: 'THA',
    value2: 'TH',
  },
  TL: {
    label: 'Timor-Leste',
    value: 'TLS',
    value2: 'TL',
  },
  TG: {
    label: 'Togo',
    value: 'TGO',
    value2: 'TG',
  },
  TK: {
    label: 'Tokelau',
    value: 'TKL',
    value2: 'TK',
  },
  TO: {
    label: 'Tonga',
    value: 'TON',
    value2: 'TO',
  },
  TT: {
    label: 'Trinidad and Tobago',
    value: 'TTO',
    value2: 'TT',
  },
  TU: {
    label: 'Tunisia',
    value: 'TUN',
    value2: 'TN',
  },
  TR: {
    label: 'Turkey',
    value: 'TUR',
    value2: 'TR',
  },
  TM: {
    label: 'Turkmenistan',
    value: 'TKM',
    value2: 'TM',
  },
  TV: {
    label: 'Tuvalu',
    value: 'TUV',
    value2: 'TV',
  },
  VI: {
    label: 'U.S. Virgin Islands',
    value: 'VIR',
    value2: 'VI',
  },
  UG: {
    label: 'Uganda',
    value: 'UGA',
    value2: 'UG',
  },
  UK: {
    label: 'Ukraine',
    value: 'UKR',
    value2: 'UA',
  },
  AE: {
    label: 'United Arab Emirates',
    value: 'ARE',
    value2: 'AE',
  },
  GB: {
    label: 'United Kingdom',
    value: 'GBR',
    value2: 'GB',
  },
  US: {
    label: 'United States',
    value: 'USA',
    value2: 'US',
  },
  UR: {
    label: 'Uruguay',
    value: 'URY',
    value2: 'UY',
  },
  UZ: {
    label: 'Uzbekistan',
    value: 'UZB',
    value2: 'UZ',
  },
  VU: {
    label: 'Vanuatu',
    value: 'VUT',
    value2: 'VU',
  },
  VE: {
    label: 'Venezuela',
    value: 'VEN',
    value2: 'VE',
  },
  VN: {
    label: 'Vietnam',
    value: 'VNM',
    value2: 'VN',
  },
  WL: {
    label: 'Wallis and Futuna',
    value: 'WLF',
    value2: 'WF',
  },
  EH: {
    label: 'Western Sahara',
    value: 'ESH',
    value2: 'EH',
  },
  YE: {
    label: 'Yemen',
    value: 'YEM',
    value2: 'YE',
  },
  ZM: {
    label: 'Zambia',
    value: 'ZMB',
    value2: 'ZM',
  },
  ZW: {
    label: 'Zimbabwe',
    value: 'ZWE',
    value2: 'ZW',
  },
  AX: {
    label: 'Åland Islands',
    value: 'ALA',
    value2: 'AX',
  },
};

export const COUNTRY_ARRAY = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'XK',
  'YE',
  'YT',
  'YY',
  'ZA',
  'ZM',
  'ZW',
  'ZZ',
];

export const LANGUAGE_LIST: { value: string; label: string }[] = [
  { value: 'en-US', label: 'English (United States of America)' },
  {
    value: 'ko-KR',
    label: 'Korean (Korea)',
  },
  {
    value: 'en-GB',
    label: 'English (United Kingdom of Great Britain and Northern Ireland)',
  },
  {
    value: 'en-AU',
    label: 'English (Australia)',
  },
  {
    value: 'en-SG',
    label: 'English (Singapore)',
  },
  { value: 'en-TW', label: 'English (Taiwan)' },
  { value: 'bg-BG', label: 'Bulgarian (Bulgaria)' },
  { value: 'bn-BD', label: 'Bengali (Bangladesh)' },
  { value: 'cs-CZ', label: 'Czech (Czech Republic)' },
  { value: 'da-DK', label: 'Danish (Denmark)' },
  { value: 'de-DE', label: 'German (Germany)' },
  { value: 'el-GR', label: 'Greek (Greece)' },
  { value: 'es-ES', label: 'Spanish (Castilian)' },
  { value: 'es-US', label: 'Spanish(Latin)' },
  { value: 'eu-ES', label: 'Basque (Spain)' },
  { value: 'fi-FI', label: 'Finnish (Finland)' },
  { value: 'fr-FR', label: 'French (France)' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'gl-ES', label: 'Galician (Spain)' },
  { value: 'hr-HR', label: 'Croatian (Croatia)' },
  { value: 'hu-HU', label: 'Hungarian (Hungary)' },
  { value: 'it-IT', label: 'Italian (Italy)' },
  { value: 'nb-NO', label: 'Norwegian (Bokm?l) (Norway)' },
  { value: 'nl-NL', label: 'Dutch (Netherlands)' },
  { value: 'nl-BE', label: 'Dutch (Belgium)' },
  { value: 'fr-BE', label: 'French (Belgium)' },
  { value: 'pl-PL', label: 'Polish (Poland)' },
  { value: 'pt-BR', label: 'Portuguese (Brazil)' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)' },
  { value: 'ro-RO', label: 'Romanian (Romania)' },
  { value: 'sk-SK', label: 'Slovak (Slovakia)' },
  { value: 'sl-SI', label: 'Slovenian (Slovenia)' },
  { value: 'sq-AL', label: 'Albanian (Albania)' },
  { value: 'sr-Cyrl-RS', label: 'Serbian (Cyrillic, Serbia)' },
  { value: 'sr-RS', label: 'Serbian (Latin, Serbia)' },
  { value: 'sv-SE', label: 'Swedish (Sweden)' },
  { value: 'et-EE', label: 'Estonian (Estonia)' },
  { value: 'zh-CN', label: 'Chinese (S)' },
  { value: 'zh-HK', label: 'Chinese (Hong Kong)' },
  { value: 'ru-RU', label: 'Russian (Russia)' },
  { value: 'ar-AE', label: 'Arabic (U.A.E.)' },
  { value: 'th-TH', label: 'Thai (Thailand)' },
  { value: 'tr-TR', label: 'Turkish (Turkey)' },
  { value: 'en-CA', label: 'English (Canada)' },
  { value: 'hy-AM', label: 'Armenian (Armenia)' },
  { value: 'as-IN', label: 'Assamese (India)' },
  { value: 'be-BY', label: 'Belarusian (Belarus)' },
  { value: 'bn-IN', label: 'Bengali (India)' },
  { value: 'ca-ES', label: 'Catalan; Valencian (Spain)' },
  { value: 'fa-IR', label: 'Persian (Iran (Islamic Republic of))' },
  { value: 'ka-GE', label: 'Georgian (Georgia)' },
  { value: 'gu-IN', label: 'Gujarati (India)' },
  { value: 'he-IL', label: 'Hebrew (Israel)' },
  { value: 'hi-IN', label: 'Hindi (India)' },
  { value: 'is-IS', label: 'Icelandic (Iceland)' },
  { value: 'ga-IE', label: 'Irish (Ireland)' },
  { value: 'ja-JP', label: 'Japanese (Japan)' },
  { value: 'kn-IN', label: 'Kannada (India)' },
  { value: 'kk-KZ', label: 'Kazakh (Kazakhstan)' },
  { value: 'km-KH', label: 'Central Khmer (Cambodia)' },
  { value: 'ky-KG', label: 'Kirghiz (Kyrgyzstan)' },
  { value: 'lo-LA', label: "Lao (Lao People's Democratic Republic)" },
  { value: 'lv-LV', label: 'Latvian (Latvia)' },
  { value: 'lt-LT', label: 'Lithuanian (Lithuania)' },
  {
    value: 'mk-MK',
    label: 'Macedonian (The former Yugoslav Republic of Macedonia)',
  },
  { value: 'ms-MY', label: 'Malay (Malaysia)' },
  { value: 'ml-IN', label: 'Malayalam (India)' },
  { value: 'mr-IN', label: 'Marathi (India)' },
  { value: 'my-MM', label: 'Burmese (Myanmar)' },
  { value: 'ne-IN', label: 'Nepali (India)' },
  { value: 'or-IN', label: 'Oriya (India)' },
  { value: 'tg-TJ', label: 'Tajik (Tajikistan)' },
  { value: 'ta-IN', label: 'Tamil (India)' },
  { value: 'te-IN', label: 'Telugu (India)' },
  { value: 'tk-TM', label: 'Turkmen (Turkmenistan)' },
  { value: 'uk-UA', label: 'Ukrainian (Ukraine)' },
  { value: 'ur-PK', label: 'Urdu (Pakistan)' },
  { value: 'ug-CN', label: 'Uyghur (China)' },
  { value: 'vi-VN', label: 'Vietnamese (Viet Nam)' },
  { value: 'id-ID', label: 'Indonesian (Indonesia)' },
  // Added more languages
  { value: 'af-ZA', label: 'Afrikaans (South Africa)' },
  { value: 'am-ET', label: 'Amharic (Ethiopia)' },
  { value: 'az-AZ', label: 'Azerbaijani (Azerbaijan)' },
  {
    value: 'bs-BA',
    label: 'Bosnian (Latin, Bosnia and Herzegovina)',
  },
  { value: 'ha-GH', label: 'Hausa (Latin, Ghana)' },
  { value: 'ha-NE', label: 'Hausa (Latin, Niger)' },
  { value: 'ha-NG', label: 'Hausa (Latin, Nigeria)' },
  { value: 'ig-NG', label: 'Igbo (Nigeria)' },
  { value: 'ks-IN', label: 'Kashmiri (Devanagari, India)' },
  // { value: 'kok-IN', label: 'Konkani (India)' },
  { value: 'sw-CD', label: 'Kiswahili (Congo DRC)' },
  { value: 'sw-KE', label: 'Kiswahili (Kenya)' },
  { value: 'sw-TZ', label: 'Kiswahili (Tanzania)' },
  { value: 'sw-UG', label: 'Kiswahili (Uganda)' },
  { value: 'ku-IQ', label: 'Kurmanji / Kurdish (Arabic, Iraq)' },
  // { value: 'mni-IN', label: 'Manipuri (India)' },
  { value: 'mi-NZ', label: 'Maori (New Zealand)' },
  { value: 'mn-MN', label: 'Mongolian (Cyrillic, Mongolia)' },
  { value: 'pa-IN', label: 'Punjabi (India)' },
  { value: 'sa-IN', label: 'Sanskrit (India)' },
  { value: 'sd-IN', label: 'Sindhi (Devanagari, India)' },
  // { value: 'fil-PH', label: 'Tagalog / Filipino (Philippines)' },
  { value: 'zh-TW', label: 'Chinese (Traditional, Taiwan)' },
  { value: 'uz-UZ', label: 'Uzbek (Latin, Uzbekistan)' },
  { value: 'xh-ZA', label: 'isiXhosa (South Africa)' },
  { value: 'yo-BJ', label: 'Yoruba (Benin)' },
  { value: 'yo-NG', label: 'Yoruba (Nigeria)' },
  { value: 'zu-ZA', label: 'isiZulu (South Africa)' },
];

export const ISO_LANGUAGE_LIST = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Abkhazian',
    value: 'ab',
  },
  {
    label: 'Afar',
    value: 'aa',
  },
  {
    label: 'Afrikaans',
    value: 'af',
  },
  {
    label: 'Akan',
    value: 'ak',
  },
  {
    label: 'Albanian',
    value: 'sq',
  },
  {
    label: 'Amharic',
    value: 'am',
  },
  {
    label: 'Arabic',
    value: 'ar',
  },
  {
    label: 'Aragonese',
    value: 'an',
  },
  {
    label: 'Armenian',
    value: 'hy',
  },
  {
    label: 'Assamese',
    value: 'as',
  },
  {
    label: 'Avaric',
    value: 'av',
  },
  {
    label: 'Avestan',
    value: 'ae',
  },
  {
    label: 'Aymara',
    value: 'ay',
  },
  {
    label: 'Azerbaijani',
    value: 'az',
  },
  {
    label: 'Bambara',
    value: 'bm',
  },
  {
    label: 'Bashkir',
    value: 'ba',
  },
  {
    label: 'Basque',
    value: 'eu',
  },
  {
    label: 'Belarusian',
    value: 'be',
  },
  {
    label: 'Bengali (Bangla)',
    value: 'bn',
  },
  {
    label: 'Bihari',
    value: 'bh',
  },
  {
    label: 'Bislama',
    value: 'bi',
  },
  {
    label: 'Bosnian',
    value: 'bs',
  },
  {
    label: 'Breton',
    value: 'br',
  },
  {
    label: 'Bulgarian',
    value: 'bg',
  },
  {
    label: 'Burmese',
    value: 'my',
  },
  {
    label: 'Catalan',
    value: 'ca',
  },
  {
    label: 'Chamorro',
    value: 'ch',
  },
  {
    label: 'Chechen',
    value: 'ce',
  },
  {
    label: 'Chichewa, Chewa, Nyanja',
    value: 'ny',
  },
  {
    label: 'Chinese',
    value: 'zh',
  },
  {
    label: 'Chuvash',
    value: 'cv',
  },
  {
    label: 'Cornish',
    value: 'kw',
  },
  {
    label: 'Corsican',
    value: 'co',
  },
  {
    label: 'Cree',
    value: 'cr',
  },
  {
    label: 'Croatian',
    value: 'hr',
  },
  {
    label: 'Czech',
    value: 'cs',
  },
  {
    label: 'Danish',
    value: 'da',
  },
  {
    label: 'Divehi, Dhivehi, Maldivian',
    value: 'dv',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'Dzongkha',
    value: 'dz',
  },

  {
    label: 'Esperanto',
    value: 'eo',
  },
  {
    label: 'Estonian',
    value: 'et',
  },
  {
    label: 'Ewe',
    value: 'ee',
  },
  {
    label: 'Faroese',
    value: 'fo',
  },
  {
    label: 'Fijian',
    value: 'fj',
  },
  {
    label: 'Finnish',
    value: 'fi',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Fula, Fulah, Pulaar, Pular',
    value: 'ff',
  },
  {
    label: 'Galician',
    value: 'gl',
  },
  {
    label: 'Gaelic (Scottish)',
    value: 'gd',
  },
  {
    label: 'Gaelic (Manx)',
    value: 'gv',
  },
  {
    label: 'Georgian',
    value: 'ka',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Greek',
    value: 'el',
  },
  {
    label: 'Greenlandic',
    value: 'kl',
  },
  {
    label: 'Guarani',
    value: 'gn',
  },
  {
    label: 'Gujarati',
    value: 'gu',
  },
  {
    label: 'Haitian Creole',
    value: 'ht',
  },
  {
    label: 'Hausa',
    value: 'ha',
  },
  {
    label: 'Hebrew',
    value: 'he',
  },
  {
    label: 'Herero',
    value: 'hz',
  },
  {
    label: 'Hindi',
    value: 'hi',
  },
  {
    label: 'Hiri Motu',
    value: 'ho',
  },
  {
    label: 'Hungarian',
    value: 'hu',
  },
  {
    label: 'Icelandic',
    value: 'is',
  },
  {
    label: 'Ido',
    value: 'io',
  },
  {
    label: 'Igbo',
    value: 'ig',
  },
  {
    label: 'Indonesian',
    value: 'id',
  },
  {
    label: 'Interlingua',
    value: 'ia',
  },
  {
    label: 'Interlingue',
    value: 'ie',
  },
  {
    label: 'Inuktitut',
    value: 'iu',
  },
  {
    label: 'Inupiak',
    value: 'ik',
  },
  {
    label: 'Irish',
    value: 'ga',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Javanese',
    value: 'jv',
  },
  {
    label: 'Kannada',
    value: 'kn',
  },
  {
    label: 'Kanuri',
    value: 'kr',
  },
  {
    label: 'Kashmiri',
    value: 'ks',
  },
  {
    label: 'Kazakh',
    value: 'kk',
  },
  {
    label: 'Khmer',
    value: 'km',
  },
  {
    label: 'Kikuyu',
    value: 'ki',
  },
  {
    label: 'Kinyarwanda (Rwanda)',
    value: 'rw',
  },
  {
    label: 'Kirundi',
    value: 'rn',
  },
  {
    label: 'Kyrgyz',
    value: 'ky',
  },
  {
    label: 'Komi',
    value: 'kv',
  },
  {
    label: 'Kongo',
    value: 'kg',
  },
  {
    label: 'Korean',
    value: 'ko',
  },
  {
    label: 'Kurdish',
    value: 'ku',
  },
  {
    label: 'Kwanyama',
    value: 'kj',
  },
  {
    label: 'Lao',
    value: 'lo',
  },
  {
    label: 'Latin',
    value: 'la',
  },
  {
    label: 'Latvian (Lettish)',
    value: 'lv',
  },
  {
    label: 'Limburgish ( Limburger)',
    value: 'li',
  },
  {
    label: 'Lingala',
    value: 'ln',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
  },
  {
    label: 'Luga-Katanga',
    value: 'lu',
  },
  {
    label: 'Luganda, Ganda',
    value: 'lg',
  },
  {
    label: 'Luxembourgish',
    value: 'lb',
  },
  {
    label: 'Macedonian',
    value: 'mk',
  },
  {
    label: 'Malagasy',
    value: 'mg',
  },
  {
    label: 'Malay',
    value: 'ms',
  },
  {
    label: 'Malayalam',
    value: 'ml',
  },
  {
    label: 'Maltese',
    value: 'mt',
  },
  {
    label: 'Maori',
    value: 'mi',
  },
  {
    label: 'Marathi',
    value: 'mr',
  },
  {
    label: 'Marshallese',
    value: 'mh',
  },
  {
    label: 'Moldavian',
    value: 'mo',
  },
  {
    label: 'Mongolian',
    value: 'mn',
  },
  {
    label: 'Nauru',
    value: 'na',
  },
  {
    label: 'Navajo',
    value: 'nv',
  },
  {
    label: 'Ndonga',
    value: 'ng',
  },
  {
    label: 'Northern Ndebele',
    value: 'nd',
  },
  {
    label: 'Nepali',
    value: 'ne',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Norwegian bokm�l',
    value: 'nb',
  },
  {
    label: 'Norwegian nynorsk',
    value: 'nn',
  },
  {
    label: 'Occitan',
    value: 'oc',
  },
  {
    label: 'Ojibwe',
    value: 'oj',
  },
  {
    label: 'Old Church Slavonic, Old Bulgarian',
    value: 'cu',
  },
  {
    label: 'Oriya',
    value: 'or',
  },
  {
    label: 'Oromo (Afaan Oromo)',
    value: 'om',
  },
  {
    label: 'Ossetian',
    value: 'os',
  },
  {
    label: 'P?li',
    value: 'pi',
  },
  {
    label: 'Pashto, Pushto',
    value: 'ps',
  },
  {
    label: 'Persian (Farsi)',
    value: 'fa',
  },
  {
    label: 'Polish',
    value: 'pl',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Punjabi (Eastern)',
    value: 'pa',
  },
  {
    label: 'Quechua',
    value: 'qu',
  },
  {
    label: 'Romansh',
    value: 'rm',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
  {
    label: 'Sami',
    value: 'se',
  },
  {
    label: 'Samoan',
    value: 'sm',
  },
  {
    label: 'Sango',
    value: 'sg',
  },
  {
    label: 'Sanskrit',
    value: 'sa',
  },
  {
    label: 'Serbian',
    value: 'sr',
  },
  {
    label: 'Serbo-Croatian',
    value: 'sh',
  },
  {
    label: 'Sesotho',
    value: 'st',
  },
  {
    label: 'Setswana',
    value: 'tn',
  },
  {
    label: 'Shona',
    value: 'sn',
  },
  {
    label: 'Sichuan Yi',
    value: 'ii',
  },
  {
    label: 'Sindhi',
    value: 'sd',
  },
  {
    label: 'Sinhalese',
    value: 'si',
  },
  {
    label: 'Slovak',
    value: 'sk',
  },
  {
    label: 'Slovenian',
    value: 'sl',
  },
  {
    label: 'Somali',
    value: 'so',
  },
  {
    label: 'Southern Ndebele',
    value: 'nr',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Sundanese',
    value: 'su',
  },
  {
    label: 'Swahili (Kiswahili)',
    value: 'sw',
  },
  {
    label: 'Swati',
    value: 'ss',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
  {
    label: 'Tagalog',
    value: 'tl',
  },
  {
    label: 'Tahitian',
    value: 'ty',
  },
  {
    label: 'Tajik',
    value: 'tg',
  },
  {
    label: 'Tamil',
    value: 'ta',
  },
  {
    label: 'Tatar',
    value: 'tt',
  },
  {
    label: 'Telugu',
    value: 'te',
  },
  {
    label: 'Thai',
    value: 'th',
  },
  {
    label: 'Tibetan',
    value: 'bo',
  },
  {
    label: 'Tigrinya',
    value: 'ti',
  },
  {
    label: 'Tonga',
    value: 'to',
  },
  {
    label: 'Tsonga',
    value: 'ts',
  },
  {
    label: 'Turkish',
    value: 'tr',
  },
  {
    label: 'Turkmen',
    value: 'tk',
  },
  {
    label: 'Twi',
    value: 'tw',
  },
  {
    label: 'Uyghur',
    value: 'ug',
  },
  {
    label: 'Ukrainian',
    value: 'uk',
  },
  {
    label: 'Urdu',
    value: 'ur',
  },
  {
    label: 'Uzbek',
    value: 'uz',
  },
  {
    label: 'Venda',
    value: 've',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
  },
  {
    label: 'Volap�k',
    value: 'vo',
  },
  {
    label: 'Wallon',
    value: 'wa',
  },
  {
    label: 'Welsh',
    value: 'cy',
  },
  {
    label: 'Wolof',
    value: 'wo',
  },
  {
    label: 'Western Frisian',
    value: 'fy',
  },
  {
    label: 'Xhosa',
    value: 'xh',
  },
  {
    label: 'Yiddish',
    value: 'yi',
  },
  {
    label: 'Yoruba',
    value: 'yo',
  },
  {
    label: 'Zhuang, Chuang',
    value: 'za',
  },
  {
    label: 'Zulu',
    value: 'zu',
  },
];

export const LANGUAGE_IDS = (() => LANGUAGE_LIST.map(lang => lang.value))();

export const LANGUAGE_NAME = (() =>
  LANGUAGE_LIST.reduce((acc, lang) => {
    // @ts-ignore
    acc[lang.value] = lang.label;
    return acc;
  }, {}))();

export const LANGUAGES_USING_SEMICOLON_FOR_DIFFERENT_PURPOSE = ['el-GR']; // TODO: please add more languages if exist

export const ACTION_ICON_BY_ACTION_TYPE = {
  preview: <Icon width="100%" height="100%" component={PreviewIconImage} />,
  delete: <Icon width="100%" height="100%" component={DeleteIconImage} />,
  submit: <Icon width="100%" height="100%" component={SubmitIconImage} />,
  approve: (
    <Icon
      width="100%"
      height="100%"
      style={{ verticalAlign: 'top' }}
      component={() => getImage24BySource(ApproveIconImage)}
    />
  ),
  reject: <Icon width="100%" height="100%" component={RejectIconImage} />,
  publish: (
    <Icon
      width="100%"
      height="100%"
      style={{ verticalAlign: 'top' }}
      component={() => getImage24BySource(PublishIconImage)}
    />
  ),
  edit: <Icon width="100%" height="100%" component={EditIconImage} />,
  edit_view: <Icon width="100%" height="100%" component={EditIconImage} />,
  unpublish: <Icon width="100%" height="100%" component={UnpublishIconImage} />,
  view: <Icon width="100%" height="100%" component={viewIconImage} />,
  history: <Icon width="100%" height="100%" component={HistoryIconImage} />,
  edit_json: <Icon width="100%" height="100%" component={EditJSONIconImage} />,
  view_json: (
    <Icon width="100%" height="100%" component={PreviewJSONIconImage} />
  ),
  move: <Icon width="100%" height="100%" component={MoveIconImage} />,
  archive: <Icon width="100%" height="100%" component={ArchiveIconImage} />,
  archived: <Icon width="100%" height="100%" component={ArchiveIconImage} />,
  close: <CloseCircleOutlined />,
  unclose: <PlayCircleOutlined />,
  unarchive: <Icon width="100%" height="100%" component={UnarchiveIconImage} />,
  replace: <SyncOutlined />,
  promote: <RocketOutlined />,
  enable: <CheckCircleOutlined />,
  disable: <Icon width="100%" height="100%" component={DisableIconImage} />,
  export: <Icon width="100%" height="100%" component={ExportIconImage} />,
  clone: <Icon width="100%" height="100%" component={CloneIconImage} />,
  copy_URL: <LinkOutlined />,
  activate: <Icon width="100%" height="100%" component={ActivateIconImage} />,
  deactivate: (
    <Icon width="100%" height="100%" component={DeactivateIconImage} />
  ),
};

export const GLOBAL_COUNTRY = 'Global Country';

export const SESSION_STORAGE_SERVICE = {
  PLUGIN: {
    TIPS: 'plugin-tips',
    SUPPORT: 'plugin-support',
  },
  ASSETS: {
    ICON: 'assets-icon',
    IMAGE: 'assets-image',
    OCF: 'assets-ocf',
  },
  EXPLORE: {
    POST: 'explore-post',
    TAG: 'explore-tag',
  },
  MALL: {
    PRODUCT: 'mall-product',
    LIFE: 'mall-life',
    CATEGORY: 'mall-category',
    BRAND: 'mall-brand',
    SUPERCATEGORY: 'mall-supercategory',
  },
  GSE: {
    PAGE: 'gse-page',
    HERO_BANNER: 'gse-herobanner',
    NOTICE: 'gse-notice',
  },
  RECOM: {
    CAMPAIGN: 'recom-campaign',
    NOTIFICATION: 'recom-notification',
  },
  ROUTINE: {
    PRESET: 'routine-preset',
    TEMPLATE: 'routine-template',
  },
  VDA: {
    DEVICE: 'vda-device',
    RESOURCE: 'vda-resource',
  },
  EXPLORE_2: {
    MOBILE: {
      POST: 'explore-mobile-post',
      TAG: 'explore-mobile-tag',
    },
    TV: {
      POST: 'explore-tv-post',
    },
    RETAIL: {
      POST: 'explore-retail-post',
      TAG: 'explore-retail-tag',
      VENUE: 'explore-retail-venue',
    },
  },
};

export const searchMenu = [
  {
    value: '/assets/images',
    label: 'Assets > Images',
    service: 'assets',
    role: [1, 2, 3, 4, 5],
  },
  {
    value: '/assets/icons',
    label: 'Assets > Icons',
    service: 'assets',
    role: [1, 2, 3, 4, 5],
  },
  {
    value: '/assets/resource_types',
    label: 'Assets > OCF Device Types',
    service: 'assets',
    role: [1, 2, 3, 4, 5],
  },
  {
    value: '/explore/mobile/post',
    label: 'Explore > Mobile > Post',
    service: 'explore',
    role: [1, 2, 3, 5, 6, 7, 9],
  },
  {
    value: '/explore/mobile/tags',
    label: 'Explore > Mobile > Tags',
    service: 'explore',
    role: [1, 2, 3, 5],
  },
  {
    value: '/explore/tv/post',
    label: 'Explore > TV > Post',
    service: 'explore',
    role: [1, 2, 3, 5, 6, 7, 9],
  },
  {
    value: '/explore/retail/post',
    label: 'Explore > Retail > Post',
    service: 'explore',
    role: [1, 2, 3, 10, 11, 12],
  },
  {
    value: '/explore/retail/tags',
    label: 'Explore > Retail > Tag',
    service: 'explore',
    role: [1, 2, 3, 10, 11, 12],
  },
  {
    value: '/explore/retail/venue',
    label: 'Explore > Retail > Venue',
    service: 'explore',
    role: [1, 2, 3, 10, 11, 12],
  },
  {
    value: '/gse/content/pages',
    label: 'GSE > Pages',
    service: 'gse',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/gse/content/herobanners',
    label: 'GSE > Hero Banners',
    service: 'gse',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/gse/content/notices',
    label: 'GSE > Notices',
    service: 'gse',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/plugin/tips',
    label: 'Plugin > Tips',
    service: 'plugin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/plugin/support-page',
    label: 'Plugin > Support Page',
    service: 'plugin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/recommendation/campaigns',
    label: 'Recommendation > My Campaign',
    service: 'recom',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/recommendation/push-recommendations',
    label: 'Recommendation > Push Recommendation',
    service: 'recom',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/routine/preset_routine',
    label: 'Routine > Preset Routine',
    service: 'routine',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/routine/routine_template',
    label: 'Routine > Routine Template',
    service: 'routine',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/mall/local_resource/product',
    label: 'Supported Devices > Product',
    service: 'sd',
    role: [1, 2, 3, 4, 5],
  },
  {
    value: '/mall/local_resource/lifestyle',
    label: 'Supported Devices > Life',
    service: 'sd',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/mall/global_resource/category',
    label: 'Supported Devices > Category',
    service: 'sd',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/mall/global_resource/supercategory',
    label: 'Supported Devices > Super Category',
    service: 'sd',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/mall/global_resource/brand',
    label: 'Supported Devices > Brand',
    service: 'sd',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/mall/universal_resource/deeplink',
    label: 'Supported Devices > Deeplink',
    service: 'sd',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/vda/device',
    label: 'Virtual Device App > Devices',
    service: 'vda',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/vda/resource',
    label: 'Virtual Device App > Resources',
    service: 'vda',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/find/policy',
    label: 'Find > Policy',
    service: 'find',
    role: [1, 2, 5],
  },
  {
    value: '/find/loi',
    label: 'Find > Location of Interest',
    service: 'find',
    role: [1, 2, 5],
  },
  {
    value: '/qr-lounge/venue',
    label: 'QR Lounge Web > Venue Management',
    service: 'qr_lounge',
    role: [1, 2, 5],
  },
  {
    value: '/did-settings',
    label: 'Utility > DID Settings',
    service: 'admin',
    role: [1, 2],
  },
  {
    value: '/dashboard/users',
    label: 'User Analytics > Users',
    service: 'admin',
    role: [1, 2],
  },
  {
    value: '/dashboard/content/gse',
    label: 'User Analytics > GSE',
    service: 'admin',
    role: [1, 2],
  },
  {
    value: '/dashboard/content/recommendation',
    label: 'User Analytics > Recommendation',
    service: 'admin',
    role: [1, 2],
  },
  {
    value: '/dashboard/content/assets',
    label: 'User Analytics > Assets',
    service: 'admin',
    role: [1, 2],
  },
  {
    value: '/user',
    label: 'User Management',
    service: 'admin',
    role: [1, 2, 6],
  },
  {
    value: '/notification',
    label: 'Notification Manager',
    service: 'admin',
    role: [1, 2, 6],
  },
  {
    value: '/support/about',
    label: 'Support > About',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/release',
    label: 'Support > Release Note',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/registration',
    label: 'User Guide > Basic Registration',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/images',
    label: 'User Guide > Images',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/icons',
    label: 'User Guide > Icons',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/ocf-device-types',
    label: 'User Guide > OCF Device Types',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/post',
    label: 'User Guide > Post',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/tags',
    label: 'User Guide > Tags',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/pages',
    label: 'User Guide > Pages',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/herobanners',
    label: 'User Guide > Hero Banners',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/notices',
    label: 'User Guide > Notices',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/tips',
    label: 'User Guide > Tips',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/support-page',
    label: 'User Guide > Support Page',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/my-campaign',
    label: 'User Guide > My Campaign',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/push-recom',
    label: 'User Guide > Push Recommendation',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/preset-routine',
    label: 'User Guide > Preset Routine',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/routine-template',
    label: 'User Guide > Routine Template',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/local-resource',
    label: 'User Guide > Local Resource',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/global-resources',
    label: 'User Guide > Global Resources',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/universal-resources',
    label: 'User Guide > Universal Resources',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/devices',
    label: 'User Guide > Devices',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    value: '/support/guide/resources',
    label: 'User Guide > Resources',
    service: 'admin',
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
];

export const MENU_ROLE_ACCESS = {
  assets: {
    role: [1, 2, 3, 4, 5],
  },
  explore: {
    role: [1, 2, 3, 5, 6, 7, 9, 10, 11, 12],
  },
  explore_non_venue: {
    role: [1, 2, 3, 5, 6, 7, 9],
  },
  explore_venue: {
    role: [1, 2, 3, 10, 11, 12],
  },
  gse: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  plugin: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  recom: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  routine: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  sd: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  vda: {
    role: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  admin: {
    role: [1, 2],
  },
  management: {
    role: [1, 2, 6],
  },
  find: {
    role: [1, 2, 5],
  },
  qr_lounge: {
    role: [1, 2, 5],
  },
};

export const phoneRes = {
  galaxy_a33_5g: {
    key: 'galaxy_a33_5g',
    label: 'Galaxy A33 5G',
    width: 9,
    height: 20,
    inch: '6.4"',
  },
  galaxy_a42_5g: {
    key: 'galaxy_a42_5g',
    label: 'Galaxy A42 5G',
    width: 9,
    height: 20,
    inch: '6.6"',
  },
  galaxy_a53_5g: {
    key: 'galaxy_a53_5g',
    label: 'Galaxy A53 5G',
    width: 9,
    height: 20,
    inch: '6.5"',
  },
  galaxy_note_20: {
    key: 'galaxy_note_20',
    label: 'Galaxy Note 20',
    width: 9,
    height: 20,
    inch: '6.7"',
  },
  galaxy_note_ultra: {
    key: 'galaxy_note_ultra',
    label: 'Galaxy Note Ultra',
    width: 9,
    height: 19.3,
    inch: '6.9"',
  },
  galaxy_s_24: {
    key: 'galaxy_s_24',
    label: 'Galaxy S24',
    width: 9,
    height: 19.5,
    inch: '6.2"',
  },
  galaxy_s_24_plus: {
    key: 'galaxy_s_24_plus',
    label: 'Galaxy S24+',
    width: 9,
    height: 19.5,
    inch: '6.7"',
  },
  galaxy_s_24_ultra: {
    key: 'galaxy_s_24_ultra',
    label: 'Galaxy S24 Ultra',
    width: 9,
    height: 19.5,
    inch: '6.8"',
  },
  galaxy_z_flip_5: {
    key: 'galaxy_z_flip_5',
    label: 'Galaxy Flip 5',
    width: 9,
    height: 22,
    inch: '6.7"',
  },
  galaxy_z_fold_5_folded: {
    key: 'galaxy_z_fold_5_folded',
    label: 'Galaxy Fold 5 Folded',
    width: 9,
    height: 21.6,
    inch: '7.55"',
  },
  galaxy_z_fold_5: {
    key: 'galaxy_z_fold_5',
    label: 'Galaxy Fold 5',
    width: 18,
    height: 21.6,
    inch: '7.55"',
  },
  iphone_se: {
    key: 'iphone_se',
    label: 'Iphone SE (3rd)',
    width: 9,
    height: 16,
    inch: '4.7"',
  },
  iphone_13_mini: {
    key: 'iphone_13_mini',
    label: 'Iphone 13 Mini',
    width: 9,
    height: 19.5,
    inch: '5.4"',
  },
  iphone_15_pro: {
    key: 'iphone_15_pro',
    label: 'Iphone 15 Pro',
    width: 9,
    height: 19.5,
    inch: '6.1"',
  },
  iphone_15_pro_max: {
    key: 'iphone_15_pro_max',
    label: 'Iphone 15 Pro Max',
    width: 9,
    height: 19.5,
    inch: '6.7"',
  },
};

export const phoneResConstant = 28;

export const roleInformation: { [key: string]: { children: any } } = {
  GLOBAL_MANAGER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GlobalRoleImage style={{ marginRight: '10px' }} /> Global Manager
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Global Manager is a user role that can manage content and users
            within a global scope of countries. <br /> This role has the
            following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              ST Management who can monitor all activities in the platform,
              using dashboard.
            </li>
            <li>This rule can grant access for all country.</li>
            <li>This role can approve global content.</li>
            <li>Purpose is for PM who requests for new content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img alt="table" src={global_manager} style={{ width: '100%' }}></img>
        </div>
      </>
    ),
  },
  GLOBAL_GENERAL_ADMIN: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GlobalRoleImage style={{ marginRight: '10px' }} /> Global General
          Admin
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Global General Admin is a user role that can manage content and
            users within a global scope of countries. <br /> This role has the
            following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>This role can create content for Global or any country.</li>
            <li>It can view content of all country.</li>
            <li>It cannot grant access.</li>
            <li>It cannot approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={global_general_admin}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  GLOBAL_SERVICE_ADMIN: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GlobalRoleImage style={{ marginRight: '10px' }} /> Global Service
          Admin
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Global Service Admin is a user role that can manage content and
            users within a global scope of countries. <br /> This role has the
            following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              This role can create content for Global or any country, for
              certain Service only (ST Air, ST Cook, etc.)
            </li>
            <li>It can view content of all country.</li>
            <li>It cannot grant access.</li>
            <li>It cannot approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={global_service_admin}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  GLOBAL_VIEWER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GlobalRoleImage style={{ marginRight: '10px' }} /> Global Viewer
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Global Viewer is a user role that can manage content and users
            within a global scope of countries. <br /> This role has the
            following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              This role can monitor all activities and dashboard of all
              countries.
            </li>
            <li>It cannot create, edit nor approve.</li>
            <li>
              Purpose is for QA in Recommendation or SmartThings Management.
            </li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img alt="table" src={global_viewer} style={{ width: '100%' }}></img>
        </div>
      </>
    ),
  },
  COUNTRY_MANAGER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Country Manager
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Country Manager is a user role that has access based on its
            registered country. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>User with limited access based on its registered country.</li>
            <li>It can only create content for its country.</li>
            <li>
              It can also view content on another country, but cannot create.
            </li>
            <li>It can grant access user for its country.</li>
            <li>It can approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={country_manager}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  COUNTRY_GENERAL_ADMIN: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Country General Admin
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Country General Admin is a user role that has access based on its
            registered country. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>User with limited access based on its registered country.</li>
            <li>
              It can only create content for its country. For all Content type &
              Services.
            </li>
            <li>
              It can also view content on another country, but cannot create.
            </li>
            <li>It cannot grant access nor approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={country_general_admin}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  COUNTRY_SERVICE_ADMIN: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Country Service Admin
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Country Service Admin is a user role that has access based on its
            registered country. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>User with limited access based on its registered country.</li>
            <li>
              It can only create content for its country. For certain Services
              only (ST Air, ST Cook, etc.)
            </li>
            <li>
              It can also view content on another country, but cannot create.
            </li>
            <li>It cannot grant access nor approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={country_service_admin}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  COUNTRY_VIEWER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Country Viewer
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Country Viewer is a user role that has access based on its
            registered country. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              This role can monitor all activities and dashboard of registered
              country.
            </li>
            <li>It cannot create, edit nor approve.</li>
            <li>
              Purpose is for QA in Recommendation or SmartThings Management.
            </li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img alt="table" src={country_viewer} style={{ width: '100%' }}></img>
        </div>
      </>
    ),
  },
  VENUE_MANAGER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Venue Manager
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Venue Manager is a user role that has access based on its registered
            country. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>User with limited access based on its registered venues.</li>
            <li>
              It can request to create venues but it needs be approved by Global
              Manager
            </li>
            <li>
              It can grant someone who has the roles: Venue Service Admin or
              Venue Viewer access to their own venues
            </li>
            <li>It can only create content for its venues.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={country_manager}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  VENUE_SERVICE_ADMIN: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Venue Service Admin
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Venue Service Admin is a user role that has access based on its
            registered venues. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>User with limited access based on its registered venues.</li>
            <li>It can only view and update their venues</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img
            alt="table"
            src={country_service_admin}
            style={{ width: '100%' }}
          ></img>
        </div>
      </>
    ),
  },
  VENUE_VIEWER: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CountryRoleImage style={{ marginRight: '10px' }} />
          Venue Viewer
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Venue Viewer is a user role that has access based on its registered
            venues. <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              This role can monitor all activities and dashboard of registered
              venues.
            </li>
            <li>It cannot create, edit nor approve.</li>
            <li>
              Purpose is for QA in Recommendation or SmartThings Management.
            </li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img alt="table" src={country_viewer} style={{ width: '100%' }}></img>
        </div>
      </>
    ),
  },
  OPERATION: {
    children: (
      <>
        <h1
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <OperationRoleImage style={{ marginRight: '10px' }} />
          Operation
        </h1>
        <div>
          <p style={{ marginBottom: '10px' }}>
            Operation is a user role that has access for operation purpose.{' '}
            <br /> This role has the following authorities:
          </p>
          <ul style={{ padding: '0px 0px 20px 20px' }}>
            <li>
              This role can create content for Global or any country, for
              certain Service only (ST Air, ST Cook, etc.)
            </li>
            <li>It can view content of all country.</li>
            <li>It can grant access.</li>
            <li>It can approve content.</li>
          </ul>
          <p style={{ fontWeight: 'bold' }}>
            User Role Table Mapping Functionality Access
          </p>
          <img alt="table" src={operation} style={{ width: '100%' }}></img>
        </div>
      </>
    ),
  },
};

export const serviceInformation: Record<
  string,
  {
    key: string;
    label: string;
    menu: string;
    children: JSX.Element;
  }
> = {
  assets: {
    key: 'assets',
    label: 'Assets',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={AssetsImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>Assets</h1>
            </Col>
          </Row>
          <Row>
            <span>
              The Assets Menu is a management storage for assets used in the
              SmartThings app. <br />
              <br />
              The Admin can upload assets in the form of images, icons, and
              animations. The content can be reviewed as a draft or requested
              before it is published to the client.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  explore: {
    key: 'explore',
    label: 'Explore (non-Retail)',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={ExploreImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Explore (non-retail)
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              There is an explore menu in the SmartThings app that helps users
              understand and learn about the whole thing related to the app.
              <br />
              <br /> Use this STCMS Explore Menu as content management to manage
              the content.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  explore_retail: {
    key: 'explore_retail',
    label: 'Explore (Retail)',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={ExploreImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Explore (Retail)
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              There is an explore menu in the SmartThings retail client that
              helps users understand and learn about the whole thing related to
              the app.
              <br />
              <br /> Use this STCMS Explore Menu as content management to manage
              the content.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  gse: {
    key: 'gse',
    label: 'GSE',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={GSEImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>GSE</h1>
            </Col>
          </Row>
          <Row>
            <span>
              GSE (Getting Start Experience) is a content management service for
              creating and managing tutorials and information for new
              SmartThings app users.
              <br />
              <br /> It has the Pages, How To Use, and Notices menu. The results
              of content creation can be seen in the SmartThings app.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  plugin: {
    key: 'plugin',
    label: 'Plugin',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={PluginImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>Plugin</h1>
            </Col>
          </Row>
          <Row>
            <span>
              Plugin is the STCMS content management that manages information
              from internal and partners to be displayed in the SmartThings
              application.
              <br />
              <br /> The partner brand devices must be connected to the
              SmartThings app through the STCMS.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  recom: {
    key: 'recom',
    label: 'Recommendation',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={RecomImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Recommendation
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              Recommendations is a content management system for providing
              feature or event recommendations from the SmartThings team to the
              user. <br />
              <br />
              Content will appear in cards in the favorites menu.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  routine: {
    key: 'routine',
    label: 'Routine',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={RoutineImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>Routine</h1>
            </Col>
          </Row>
          <Row>
            <span>
              The Routine menu is a system for managing templates for routines
              in the SmartThings App.
              <br />
              <br /> Admin can create routines with conditions and actions based
              on user behavior or device capabilities to make SmartThings users
              easier to manage routines for their devices.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  sd: {
    key: 'sd',
    label: 'Supported Devices',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={SDImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Supported Devices
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              Supported Devices is a management system for managing supported
              device menu content on the SmartThings app.
              <br />
              <br /> In this menu, the user can find out which devices can be
              connected to SmartThings.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  vda: {
    key: 'vda',
    label: 'Virtual Device App',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={VDAImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Virtual Device App
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              Samsung has a virtual device app to simulate actual devices.
              <br />
              <br /> The STCMS Virtual Device App menu is a management system
              for managing content to be used for the simulation process.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  map_decor: {
    key: 'map_decor',
    label: 'Map Decor',
    menu: 'content',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>{/* <Image alt="" src={MapDecorImage} /> */}</Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={service_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Map Decor
              </h1>
            </Col>
          </Row>
          <Row>
            <span>Map Decor</span>
          </Row>
        </Col>
      </Row>
    ),
  },
  find: {
    key: 'find',
    label: 'Find Node Management',
    menu: 'b2b',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={FindImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={b2b_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Find Node Management
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              The System is designed to efficiently control the transmission
              frequency of location information through findable device, through
              a node. <br /> <br />
              Users get the benefit from improved location accuracy, allowing
              real-time device location display on the app map.
              <br /> <br />
              User can create a policy and specify its deployment location by
              country or in its longitude and latitude numbers. <br /> <br />
              User configure policies that will be used in the desired location.
              The content is controlled to appear on devices according to the
              rules applied.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
  qr_lounge: {
    key: 'qr_lounge',
    label: 'QR Lounge Web',
    menu: 'b2b',
    children: (
      <Row style={{ minHeight: '550px' }}>
        <Col span={14}>
          <Image alt="" src={QRLoungeImage} />
        </Col>
        <Col span={10} style={{ padding: '5px 20px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <img alt="" src={b2b_icon} />
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <h1 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                QR Lounge - Venue Management
              </h1>
            </Col>
          </Row>
          <Row>
            <span>
              The QR Lounge Web Service provide SmartThings <br />
              experience for users through their own devices within
              <br />
              the offline retail stores. This services creates an <br />
              experience QR based on the room, device, routine, etc.
              <br />
              <br />
              Experience Zone managers of offline retail stores can be
              <br />
              signed up for this service by getting the approval for a
              <br />
              request venue through STCMS.
            </span>
          </Row>
        </Col>
      </Row>
    ),
  },
};
